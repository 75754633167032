import axios from 'axios';

// const baseApiUrl = "http://localhost:80";
// const baseApiUrl = "http://a9bbcf0f2f1f04cb9b6f96b0fb1d03f7-1824144351.us-east-1.elb.amazonaws.com";
const baseApiUrl = 'https://dev.flackre.com';
const defaultHeaders = {
  Accept: 'application/json, application/javascript',
  'Content-Type': 'application/json',
};

const formDataHeaders = {
  Accept: 'application/json, application/javascript',
  'Content-Type': 'multipart/form-data',
};

class AxiosService {
  constructor() {
    this.instance = axios.create({
      baseURL: baseApiUrl,
      headers: defaultHeaders,
      withCredentials: false,
    });
  }

  setAuthToken(token) {
    if (token) {
      this.instance.defaults.headers.common.Authorization = token;
    } else {
      delete this.instance.defaults.headers.common.Authorization;
    }
  }

  createConfigForFormData() {
    return {
      baseURL: baseApiUrl,
      headers: { ...formDataHeaders, ...this.instance.defaults.headers.common },
      withCredentials: false,
    };
  }

  GetAnonymous(url) {
    return this.instance.get(url);
  }

  PostAnonymous(url, data) {
    return this.instance.post(url, data);
  }

  Get(url, config = {}) {
    return this.instance.get(url, { ...config, data: {} });
  }

  Post(url, data, config = {}) {
    return this.instance.post(url, data, config);
  }

  Put(url, data) {
    return this.instance.put(url, data);
  }

  Delete(url, data) {
    return this.instance.delete(url, { data });
  }
}

const axiosService = new AxiosService();

export default axiosService;
