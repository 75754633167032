import { API_ENDPOINTS } from './apiConstants';
import axiosService from './axiosService';

export const invitationsGetAll = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.INVITATIONS.GET_ALL_INVITATIONS);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const invitationGetById = async (id) => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.INVITATIONS.GET_INVITATION_BY_ID(id));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const invitationGetByToken = async (token) => {
  try {
    const response = await axiosService.Get(
      API_ENDPOINTS.INVITATIONS.GET_INVITATION_BY_TOKEN(token),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const respondToInvitation = async (response) => {
  try {
    await axiosService.Post(API_ENDPOINTS.INVITATIONS.RESPOND_TO_INVITATION, {
      ...response,
    });
  } catch (error) {
    throw error;
  }
};
