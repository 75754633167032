import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  IconButton,
  styled,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import notify from 'app/utils/notify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '5%',
  paddingRight: '5%',
  marginBottom: '4%',
  borderRadius: '4px',
  marginTop: '0px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const Inputlabel = styled('div')({
  marginBottom: '5%',
});

const validationSchema = Yup.object().shape({
  contactPerson: Yup.string().required('Contact Person is required'),
  emailId: Yup.string().email('Invalid email format').matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'Invalid email domain'
  ).required('Email is required'),
  phoneNo: Yup.string().required('Phone Number is required').min(10, 'Phone Number must be at least 10 digits long'),
});

const GCSiteManagerDetailsModal = ({ SiteManagerDetail, handleCloseModal, gcSiteManager, gcSiteManagerIndex }) => {
  const { addGCManagerSiteManager, updateManagerDetails } = useProject();

  const initialValues = {
    contactPerson: SiteManagerDetail?.contactPerson || '',
    emailId: SiteManagerDetail?.emailId || '',
    phoneNo: SiteManagerDetail?.phoneNo || '',
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const siteManagerDetails = {
        contactPerson: values.contactPerson,
        emailId: values.emailId,
        phoneNo: values.phoneNo,
        type: "GC_SITE_MANAGER"
      };
      if (SiteManagerDetail) {
        const updatedSiteManager = {
          contactId:SiteManagerDetail?.contactId,
          ...siteManagerDetails,
        }
        await updateManagerDetails(gcSiteManager[gcSiteManagerIndex]?.projectId, gcSiteManager[gcSiteManagerIndex]?.id, updatedSiteManager);
       
      } else {
        await addGCManagerSiteManager(gcSiteManager[gcSiteManagerIndex]?.projectId, gcSiteManager[gcSiteManagerIndex]?.id, siteManagerDetails);
      }
      handleCloseModal();
    } catch (error) {
      notify.error(`Error in Manager Details: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        width: '35%',
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 0,
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Title>{SiteManagerDetail ? 'Edit' : 'Add'} GC Site Manager Details</Title>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon
            style={{
              fontSize: 20,
              color: '#272937',
            }}
          />
        </IconButton>
      </Box>
      <ModalDivider />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container>
              <Grid style={{ marginTop: '1px' }} container spacing={2}>
                <Grid item xs={6}>
                  <Inputlabel><InputLabel>Contact Person</InputLabel></Inputlabel>
                  <TextField
                    name='contactPerson'
                    size='small'
                    fullWidth
                    variant='outlined'
                    placeholder='Contact Person'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.contactPerson}
                    error={touched.contactPerson && Boolean(errors.contactPerson)}
                    helperText={touched.contactPerson && errors.contactPerson}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Inputlabel><InputLabel>Email Address</InputLabel></Inputlabel>
                  <TextField
                    name='emailId'
                    size='small'
                    fullWidth
                    variant='outlined'
                    placeholder='Email Address'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.emailId}
                    error={touched.emailId && Boolean(errors.emailId)}
                    helperText={touched.emailId && errors.emailId}
                  />
                </Grid>
              </Grid>
              <Grid style={{ marginTop: '1px' }} container spacing={2}>
                <Grid item xs={6}>
                  <Inputlabel><InputLabel>Phone Number</InputLabel></Inputlabel>
                  <TextField
                    name='phoneNo'
                    size='small'
                    fullWidth
                    variant='outlined'
                    placeholder='Phone Number'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneNo}
                    error={touched.phoneNo && Boolean(errors.phoneNo)}
                    helperText={touched.phoneNo && errors.phoneNo}
                  />
                </Grid>
              </Grid>
            </Container>
            <Box
              marginBottom={2.5}
              marginLeft={3.5}
              marginRight={2.5}
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <LoadingButton
                className="cancelButton"
                color="error"
                variant="outlined"
                onClick={handleCloseModal}
                style={{ boxShadow: 'none' }}
                disabled={isSubmitting}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="primary"
                style={{ boxShadow: 'none', marginRight: '10px' }}
                type="submit"
                loading={isSubmitting}
              >
                {SiteManagerDetail ? 'Update' : 'Save'}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default GCSiteManagerDetailsModal;



