import ImagesProvider from 'app/contexts/ImagesContext';
import { DownArrow, RightArrow } from 'app/common/icons';
import { Box, styled, Typography } from '@mui/material';
import { useState } from 'react';
import ImageGalleryModal from 'app/common/ImageGalleryModal';
import StepperImages from 'app/common/StepperImages';
import ImageContainer from 'app/common/ImageContainer';
import useImagesContext from 'app/hooks/useImagesContext';
import ImagesModal from './LeadDetailsImage';
const TitleImage = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
  marginLeft: '15px',
}));

function LeadImageGallery() {
  const [showImages, setShowImages] = useState(false);
  const [openAddImageModal, setOpenAddImageModal] = useState(false);
  const { totalImagesCount } = useImagesContext();

  const handleArrowClick = () => {
    setShowImages(!showImages);
  };
  const handleOpenAddImageModal = () => {
    setOpenAddImageModal(true);
  };

  const handleCloseAddImageModal = () => {
    setOpenAddImageModal(false);
  };
  return (
    <Box sx={{ padding: '24px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={handleArrowClick} style={{ cursor: 'pointer', marginRight: '10px' }}>
            {showImages ? <DownArrow /> : <RightArrow />}
            <TitleImage>{`${totalImagesCount} Images`}</TitleImage>
          </div>
          <ImageGalleryModal>
            <Typography color="primary" variant="body1" style={{ cursor: 'pointer', marginLeft: '10px' }}>
              See All
            </Typography>
          </ImageGalleryModal>
        </div>
          <Typography color="primary" variant="body1" style={{ cursor: 'pointer' }} onClick={handleOpenAddImageModal}>
            + Add Image(s)
          </Typography>
          <ImagesModal open={openAddImageModal} handleClose={handleCloseAddImageModal} />
      </Box>

      {showImages && (
        <div>
          <StepperImages>
            <ImageContainer />
          </StepperImages>
        </div>
      )}
    </Box>
  );
}

export default LeadImageGallery;
