import { RequestsIcon } from './common/icons';

export const navigations = [
  // { label: 'Menu', type: 'label' },
  // { name: 'Overview', path: '/dashboard/overview', icon: 'dashboard' },

  {
    name: 'Projects',
    icon: 'library_add_check_rounded_icon',

    children: [
      {
        name: 'Leads',
        iconText: 'SI',
        path: '/',
        APIFieldName: 'lead',
      },
      {
        name: 'New Projects',
        iconText: 'SU',
        path: '/newproject',
        APIFieldName: 'newproject',
      },
      {
        name: 'On-Going Projects',
        iconText: 'FP',
        path: '/ongoing',
        APIFieldName: 'ongoing',
      },
      {
        name: 'On Hold',
        iconText: '404',
        path: '/onhold',
        APIFieldName: 'onhold',
      },
      {
        name: 'Cancelled',
        iconText: '404',
        path: '/cancelled',
        APIFieldName: 'cancelled',
      },
      {
        name: 'Completed',
        iconText: '404',
        path: '/completed',
        APIFieldName: 'completed',
      },
    ],
  },
  {
    name: 'Requests',
    path: '/requests',
    icon: <RequestsIcon />,
  },
  // { name: 'Settings', path: '/settings', icon: 'settings' },
];
