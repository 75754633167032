import React from 'react';
import { CircularProgress, Typography, Box, Grid, Container } from '@mui/material';
import { formatCurrency } from 'app/utils/helpers';

const LeadsProgress = ({ total = 0, sold = 0, available = 0, cost = 0 }) => {
  const availablePercentage = (available / total) * 100;
  const soldPercentage = (sold / total) * 100;
  const firstDeg = (availablePercentage / 100) * 360 - 90;

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='center' height='25vh'>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Box position='relative' display='inline-flex' mr={4}>
            <CircularProgress
              variant='determinate'
              value={availablePercentage}
              size={150}
              thickness={6}
              style={{ color: '#4F90F0' }}
            />
            <CircularProgress
              variant='determinate'
              value={soldPercentage}
              size={150}
              thickness={6}
              style={{
                color: '#F03F4A',
                transform: `rotate(${firstDeg}deg)`,
                position: 'absolute',
              }}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position='absolute'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Typography variant='h4' component='div' color='#4F90F0' fontWeight='600'>
                {total}
                <Typography color='black'>Total Shares</Typography>
              </Typography>
            </Box>
          </Box>
          <Container>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              component='form'
              noValidate
              autoComplete='off'
              sx={{ mt: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h4' color='#4F90F0' fontWeight='600'>
                        {available} <Typography color='black'>Available Shares</Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h4' color='#F03F4A' fontWeight='600'>
                        {sold} <Typography color='black'>Sold Shares</Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h4' color='#4867D2' fontWeight='600'>
                        {formatCurrency(cost)}{' '}
                        <Typography color='black'>Each share Cost</Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};
export default LeadsProgress;
