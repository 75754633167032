import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField } from '@mui/material';
import notify from 'app/utils/notify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '0.6250em',
  paddingRight: '0.6250em',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const Inputlabel = styled('div')({
  marginBottom: '5%',
});

const validationSchema = Yup.object().shape({
  loanMonthlyPayment: Yup.number()
    .required('Loan Monthly Payment is required')
    .positive('Payment must be positive'),
  loanPayment: Yup.number()
    .required('Loan Payment is required')
    .positive('Payment must be positive'),
  paidBy: Yup.string().required('Paid By is required'),
});

const PaymentDetailsModal = ({
  PaymentDetails,
  handleCloseModal,
  gcpaymentsData,
  clPaymentsIndex,
}) => {
  const { addCLPaymentDetails, updateCLPaymentDetails } = useProject();

  const initialValues = {
    loanMonthlyPayment: PaymentDetails?.loanMonthlyPayment || '',
    loanPayment: PaymentDetails?.loanPayment || '',
    paidBy: PaymentDetails?.paidBy || '',
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const clPaymentDetails = {
        loanMonthlyPayment: Number(values.loanMonthlyPayment),
        loanPayment: Number(values.loanPayment),
        paidBy: values.paidBy,
      };
      if (PaymentDetails) {
        const updatedCLP = {
          paymentId: PaymentDetails?.paymentId,
          ...clPaymentDetails,
        };
        await updateCLPaymentDetails(
          gcpaymentsData[clPaymentsIndex]?.projectId,
          gcpaymentsData[clPaymentsIndex]?.id,
          updatedCLP,
        );
      } else {
        await addCLPaymentDetails(
          gcpaymentsData[clPaymentsIndex]?.projectId,
          gcpaymentsData[clPaymentsIndex]?.id,
          clPaymentDetails,
        );
      }
      handleCloseModal();
    } catch (error) {
      notify.error(`Error in Manager Details: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{PaymentDetails ? 'Edit' : 'Add'} Loan Payment Details</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: '#272937',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Loan Monthly Payment</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='number'
                        name='loanMonthlyPayment'
                        value={values.loanMonthlyPayment}
                        onChange={handleChange}
                        placeholder='e.g. $2000.00'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.loanMonthlyPayment && errors.loanMonthlyPayment)}
                        helperText={touched.loanMonthlyPayment && errors.loanMonthlyPayment}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Loan Payment</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='number'
                        name='loanPayment'
                        value={values.loanPayment}
                        onChange={handleChange}
                        placeholder='e.g. $1800.00'
                        fullWidth
                        variant='outlined'
                        size='small'
                        inputProps={{ min: 0 }}
                        error={Boolean(touched.loanPayment && errors.loanPayment)}
                        helperText={touched.loanPayment && errors.loanPayment}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Paid By</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='paidBy'
                        value={values.paidBy}
                        onChange={handleChange}
                        placeholder='e.g. James'
                        fullWidth
                        variant='outlined'
                        size='small'
                        inputProps={{ min: 0 }}
                        error={Boolean(touched.paidBy && errors.paidBy)}
                        helperText={touched.paidBy && errors.paidBy}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '16px',
                        marginBottom: '10px',
                      }}
                    >
                      <LoadingButton
                        className='cancelButton'
                        color='error'
                        variant='outlined'
                        onClick={handleCloseModal}
                        style={{ boxShadow: 'none' }}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        variant='contained'
                        color='primary'
                        type='submit'
                        loading={isSubmitting}
                      >
                        {isSubmitting ? 'Update' : 'Save'}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Container>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </div>
  );
};

export default PaymentDetailsModal;
