import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import styles from './modules/RequestCard.module.css';
import { LoadingButton } from '@mui/lab';
import RequestActions from './RequestActions';
import { useRequests } from 'app/contexts/RequestsContext';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { convertUTCDateToLocalDate } from 'app/utils/helpers';

function RequestCard({ request, respondToRequest }) {

  const rolesText = request.roles?.join(', ') || 'someone';
  const primaryText = `Someone wants to add you as ${rolesText} in ${request.projectTitle}`;
  const time = convertUTCDateToLocalDate(request.createdTime);

  return (
    <ListItem className={styles.requestCard} disablePadding>
      <ListItemText primary={primaryText} secondary={<TimeAgo date={time} />} />
      <Box className={styles.buttonsContainer}>
        <LoadingButton variant='text' sx={{ color: '#4F90F0' }}>
          <Link to={`/project/${request.projectId}`}>View Details</Link>
        </LoadingButton>
        <RequestActions request={request} respondToRequest={respondToRequest} />
      </Box>
    </ListItem>
  );
}

export default RequestCard;
