// import React, { useState, useEffect, useRef } from 'react';
// import {
//     Box,
//     Button,
//     Grid,
//     TextField,
//     Modal,
//     IconButton,
//     styled,
// } from '@mui/material';
// import { StandaloneSearchBox } from '@react-google-maps/api';
// import CloseIcon from '@mui/icons-material/Close';
// import { Title } from 'app/common/Typography';
// import LeadSaleCard from './LeadSaleCard';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { parseAddressComponents } from 'app/utils/helpers';
// import { useLead } from 'app/contexts/LeadContext';
// const ModalDivider = styled('div')({
//     height: '1px',
//     marginBottom: '20px',
//     backgroundColor: '#D5D5DD',
// });

// const LeadSaleForm = ({ open, onClose, selectedBuilding, selectedUnit, saleToEdit }) => {
//     const [saleDetailsList, setSaleDetailsList] = useState([]);
//     const [showSaleDetails, setShowSaleDetails] = useState(false);
//     const [leadData, setLeadData] = useState(null);
//     const [editMode, setEditMode] = useState(false);
//     const mapRef = useRef(null);
//     const markerRef = useRef(null);
//     const searchInputRef = useRef(null);

//     const { addSaleDetails, updateSaleDetails } = useLead();

//     const validationSchema = Yup.object({
//         buyerName: Yup.string().required('Buyer Name is required'),
//         buyerContactPerson: Yup.string().required('Contact Person is required'),
//         email: Yup.string().email('Invalid email address').required('Email is required'),
//         phoneNo: Yup.string().matches(/^\d+$/, 'Phone number must be digits only').required('Phone Number is required'),
//         address: Yup.string().required('Address is required'),
//         city: Yup.string().required('City is required'),
//         state: Yup.string().required('State is required'),
//         country: Yup.string().required('Country is required'),
//         zipCode: Yup.string().matches(/^\d+$/, 'Zip Code must be digits only').required('Zip Code is required'),
//     });

//     const formik = useFormik({
//         initialValues: {
//             buyerName: '',
//             buyerContactPerson: '',
//             address: '',
//             city: '',
//             state: '',
//             country: '',
//             zipCode: '',
//             phoneNo: '',
//             email: '',
//         },
//         validationSchema,
//         onSubmit: (values) => {
//             setLeadData(values);
//             setShowSaleDetails(true);
//         },
//     });

//     useEffect(() => {
//         if (saleToEdit) {
//             setEditMode(true);
//             formik.setValues({
//                 buyerName: saleToEdit.buyerName || '',
//                 buyerContactPerson: saleToEdit.buyerContactPerson || '',
//                 address: saleToEdit.address || '',
//                 city: saleToEdit.city || '',
//                 state: saleToEdit.state || '',
//                 country: saleToEdit.country || '',
//                 zipCode: saleToEdit.zipCode || '',
//                 phoneNo: saleToEdit.phoneNo || '',
//                 email: saleToEdit.email || '',
//             });
//         } else {
//             setEditMode(false);
//             formik.resetForm();
//         }
//     }, [saleToEdit]);

//     const handlePlacesChanged = () => {
//         const [place] = searchInputRef.current.getPlaces();
//         if (!place) return;

//         const lat = place.geometry.location.lat();
//         const lng = place.geometry.location.lng();

//         const addressData = parseAddressComponents(place);
//         const shortAddress = place.formatted_address.split(',')[0];

//         formik.setValues(prevState => ({
//             ...prevState,
//             ...addressData,
//             address: shortAddress,
//         }));

//         if (markerRef.current) {
//             markerRef.current.setPosition({ lat, lng });
//         }

//         if (mapRef.current) {
//             mapRef.current.panTo({ lat, lng });
//         }
//     };
//     const handleSaveSubmit = async (saleData) => {
//         const finalObject = {
//             buildingName: selectedBuilding.buildingName,
//             buildingId: selectedBuilding.id,
//             unitId: selectedUnit.unitId,
//             unitDetails: {
//                 buyerDetails: {
//                     buyerName: leadData.buyerName,
//                     contactPerson: leadData.buyerContactPerson,
//                     email: leadData.email,
//                     phoneNo: leadData.phoneNo,
//                     address: leadData.address,
//                     country: leadData.country,
//                     state: leadData.state,
//                     city: leadData.city,
//                     zipCode: leadData.zipCode
//                 },
//                 saleDetails: {
//                     buyerRealtor: saleData.buyerRealtor,
//                     saleSpace: saleData.saleSpace,
//                     unitNo: saleData.unitNo,
//                     salePrice: saleData.salePrice,
//                     saleExecutionDate: saleData.saleExecutionDate,
//                     saleFixturizationDate: saleData.saleFixturizationDate,
//                     saleStartDate: saleData.saleStartDate,
//                     saleEndDate: saleData.saleEndDate,
//                     buyerSaleCommission: saleData.buyerSaleCommission,
//                     sellerSaleCommission: saleData.sellerSaleCommission,
//                     closingCost: saleData.closingCost,
//                     titlePolicyCost: saleData.titlePolicyCost,
//                     comments: saleData.comments
//                 }
//             }
//         };

//         console.log("Final object to submit:", finalObject);
//         if (editMode) {
//             await updateSaleDetails(finalObject);
//         } else {
//             setSaleDetailsList(prevList => [...prevList, finalObject]);
//             await addSaleDetails(finalObject);
//         }
//     };

//     return (
//         <>
//             <Modal open={open && !showSaleDetails} closeAfterTransition>
//                 <Box
//                     sx={{
//                         position: 'absolute',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         width: '35%',
//                         bgcolor: 'background.paper',
//                         boxShadow: 24,
//                         p: 4,
//                         borderRadius: 2,
//                         overflowY: 'auto',
//                         maxHeight: '90vh',
//                     }}
//                 >
//                     <Box
//                         sx={{
//                             justifyContent: 'space-between',
//                             display: 'flex',
//                         }}
//                     >
//                         <Title>Sale Details {editMode ? 'Edit' : 'Add'}</Title>
//                         <IconButton onClick={onClose}>
//                             <CloseIcon
//                                 style={{
//                                     fontSize: 18,
//                                     color: '#272937',
//                                 }}
//                             />
//                         </IconButton>
//                     </Box>

//                     <ModalDivider />

//                     <form onSubmit={formik.handleSubmit} noValidate>
//                         <Grid container spacing={3}>
//                             <Grid item xs={6}>
//                                 <TextField
//                                     label="Buyer Name"
//                                     name="buyerName"
//                                     size="small"
//                                     fullWidth
//                                     value={formik.values.buyerName}
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     error={formik.touched.buyerName && Boolean(formik.errors.buyerName)}
//                                     helperText={formik.touched.buyerName && formik.errors.buyerName}
//                                 />
//                             </Grid>

//                             <Grid item xs={6}>
//                                 <TextField
//                                     label="Contact Person"
//                                     name="buyerContactPerson"
//                                     size="small"
//                                     fullWidth
//                                     value={formik.values.buyerContactPerson}
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     error={formik.touched.buyerContactPerson && Boolean(formik.errors.buyerContactPerson)}
//                                     helperText={formik.touched.buyerContactPerson && formik.errors.buyerContactPerson}
//                                 />
//                             </Grid>

//                             <Grid item xs={6}>
//                                 <TextField
//                                     label="Email"
//                                     name="email"
//                                     size="small"
//                                     fullWidth
//                                     value={formik.values.email}
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     error={formik.touched.email && Boolean(formik.errors.email)}
//                                     helperText={formik.touched.email && formik.errors.email}
//                                 />
//                             </Grid>

//                             <Grid item xs={6}>
//                                 <TextField
//                                     name='phoneNo'
//                                     label='Phone Number'
//                                     size='small'
//                                     fullWidth
//                                     value={formik.values.phoneNo}
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
//                                     helperText={formik.touched.phoneNo && formik.errors.phoneNo}
//                                 />
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <StandaloneSearchBox
//                                     onLoad={(searchBox) => (searchInputRef.current = searchBox)}
//                                     onPlacesChanged={handlePlacesChanged}
//                                 >
//                                     <TextField
//                                         label="Address"
//                                         name="address"
//                                         size="small"
//                                         fullWidth
//                                         value={formik.values.address}
//                                         onChange={formik.handleChange}
//                                         onBlur={formik.handleBlur}
//                                         error={formik.touched.address && Boolean(formik.errors.address)}
//                                         helperText={formik.touched.address && formik.errors.address}
//                                     />
//                                 </StandaloneSearchBox>
//                             </Grid>

//                             <Grid item xs={6}>
//                                 <TextField
//                                     label="City"
//                                     name="city"
//                                     size="small"
//                                     fullWidth
//                                     value={formik.values.city}
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     error={formik.touched.city && Boolean(formik.errors.city)}
//                                     helperText={formik.touched.city && formik.errors.city}
//                                 />
//                             </Grid>

//                             <Grid item xs={6}>
//                                 <TextField
//                                     label="State"
//                                     name="state"
//                                     size="small"
//                                     fullWidth
//                                     value={formik.values.state}
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     error={formik.touched.state && Boolean(formik.errors.state)}
//                                     helperText={formik.touched.state && formik.errors.state}
//                                 />
//                             </Grid>

//                             <Grid item xs={6}>
//                                 <TextField
//                                     label="Country"
//                                     name="country"
//                                     size="small"
//                                     fullWidth
//                                     value={formik.values.country}
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     error={formik.touched.country && Boolean(formik.errors.country)}
//                                     helperText={formik.touched.country && formik.errors.country}
//                                 />
//                             </Grid>

//                             <Grid item xs={6}>
//                                 <TextField
//                                     label="Zip Code"
//                                     name="zipCode"
//                                     size="small"
//                                     fullWidth
//                                     value={formik.values.zipCode}
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
//                                     helperText={formik.touched.zipCode && formik.errors.zipCode}
//                                 />
//                             </Grid>

//                             <ModalDivider />

//                             <Grid item xs={12} textAlign="center" display="flex" justifyContent="space-between">
//                                 <Button variant="outlined" color="error" onClick={onClose}>
//                                     Cancel
//                                 </Button>
//                                 <Button type="submit" variant="contained">
//                                     Save
//                                 </Button>
//                             </Grid>
//                         </Grid>
//                     </form>
//                 </Box>
//             </Modal>
//             {/* {showSaleDetails && (
//                 <LeadSaleDetailForm
//                     open={showSaleDetails}
//                     onClose={() => setShowSaleDetails(false)}
//                     onSave={(saleData) => {
//                         handleSaveSubmit(saleData);
//                         setShowSaleDetails(false);
//                     }}
//                 />
//             )} */}

//             {saleDetailsList.map((sale, index) => (
//                 <LeadSaleCard key={index} saleDetails={sale} />
//             ))}
//         </>
//     );
// };

// export default LeadSaleForm;

import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Modal, IconButton, styled } from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import { LoadingButton } from '@mui/lab';
import LeadSaleCard from './LeadSaleCard';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { parseAddressComponents } from 'app/utils/helpers';
import SaleDetailsForm from './leadSaleDetailForm';
import { useProject } from 'app/contexts/ProjectContext';
import notify from 'app/utils/notify';
import { getAllSalesDetails } from 'app/contexts/leadActions';
import { useParams } from 'react-router-dom';

const ModalDivider = styled('div')({
  height: '1px',
  marginBottom: '20px',
  backgroundColor: '#D5D5DD',
});

const LeadSaleForm = ({
  open,
  onClose,
  selectedBuilding,
  selectedUnit,
  initialValues,
  buyerDetails,
  selectedunitIndex,
  buildingIndex,
  saleIndex,
}) => {
  const [saleDetailsList, setSaleDetailsList] = React.useState([]);
  const mapRef = React.useRef(null);
  const markerRef = React.useRef(null);
  const searchInputRef = React.useRef(null);
  const [showSaleDetails, setShowSaleDetails] = useState(false);
  const [leadData, setLeadData] = useState(null);
  const { addSaleDetails, leasesales } = useProject();
  // const { projectId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saleDetails, setSaleDetails] = useState([]);

  const fetchSaleDetails = async (projectId) => {
    try {
      const salesData = await getAllSalesDetails(projectId);
      const filteredSales = salesData.filter(
        (sale) => sale?.unitId === leasesales[buildingIndex].units[selectedunitIndex]?.unitId,
      );
      setSaleDetails(filteredSales);
    } catch (error) {
      notify.error('Error in fetching Sale Details');
    }
  };

  const validationSchema = Yup.object({
    buyerName: Yup.string().required('Buyer Name is required'),
    buyerContactPerson: Yup.string().required('Contact Person is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNo: Yup.string()
      .matches(/^\d+$/, 'Phone number must be digits only')
      .required('Phone Number is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zipCode: Yup.string().required('Zip Code is required'),
  });

  const formik = useFormik({
    initialValues: {
      buyerName: buyerDetails?.buyerName || '',
      buyerContactPerson: buyerDetails?.contactPerson || '',
      address: buyerDetails?.address || '',
      city: buyerDetails?.city || '',
      state: buyerDetails?.state || '',
      country: buyerDetails?.country || '',
      zipCode: buyerDetails?.zipCode || '',
      phoneNo: buyerDetails?.phoneNo || '',
      email: buyerDetails?.email || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      setLeadData(values);
      setShowSaleDetails(true);
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const parseAddressComponents = (place) => {
    const components = place.address_components;
    const addressData = {};

    components.forEach((component) => {
      const types = component.types;
      if (types.includes('locality')) {
        addressData.city = component.long_name; // City
      }
      if (types.includes('administrative_area_level_1')) {
        addressData.state = component.short_name; // State
      }
      if (types.includes('country')) {
        addressData.country = component.long_name; // Country
      }
      if (types.includes('postal_code')) {
        addressData.zipCode = component.long_name; // Zip Code
      }
    });

    return addressData;
  };

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const addressData = parseAddressComponents(place);
    const shortAddress = place.formatted_address.split(',')[0];

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
      address: shortAddress,
    }));

    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    }

    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  const handleSaveSubmit = async (saleData) => {
    try {
      setIsSubmitting(true);
      const finalObject = {
        buildingName: selectedBuilding?.buildingName,
        buildingId: selectedBuilding?.id,
        unitId: selectedUnit?.unitId,
        unitDetails: {
          buyerDetails: {
            buyerName: leadData?.buyerName,
            contactPerson: leadData?.buyerContactPerson,
            email: leadData?.email,
            phoneNo: leadData?.phoneNo,
            address: leadData?.address,
            country: leadData?.country,
            state: leadData?.state,
            city: leadData?.city,
            zipCode: leadData?.zipCode,
          },
          saleDetails: {
            buyerRealtor: saleData?.buyerRealtor,
            saleSpace: saleData?.saleSpace,
            unitNo: saleData?.unitNo,
            salePrice: saleData?.salePrice,
            saleExecutionDate: saleData?.saleExecutionDate,
            saleFixturizationDate: saleData?.saleFixturizationDate,
            saleStartDate: saleData?.saleStartDate,
            saleEndDate: saleData?.saleEndDate,
            buyerSaleCommission: saleData?.buyerSaleCommission,
            sellerSaleCommission: saleData?.sellerSaleCommission,
            closingCost: saleData?.closingCost,
            titlePolicyCost: saleData?.titlePolicyCost,
            comments: saleData?.comments,
          },
        },
      };

      const updatedObject = {
        buildingName: saleDetails[saleIndex]?.buildingName,
        buildingId: saleDetails[saleIndex]?.buildingId,
        unitId: saleDetails[saleIndex]?.unitId,
        id: saleDetails[saleIndex]?.id,
        unitDetails: {
          buyerDetails: {
            ...saleDetails[saleIndex]?.unitDetails?.buyerDetails, // Spread existing buyer details
            buyerName:
              leadData?.buyerName || saleDetails[saleIndex]?.unitDetails?.buyerDetails?.buyerName,
            contactPerson:
              leadData?.buyerContactPerson ||
              saleDetails[saleIndex]?.unitDetails?.buyerDetails?.contactPerson,
            email: leadData?.email || saleDetails[saleIndex]?.unitDetails?.buyerDetails?.email,
            phoneNo:
              leadData?.phoneNo || saleDetails[saleIndex]?.unitDetails?.buyerDetails?.phoneNo,
            address:
              leadData?.address || saleDetails[saleIndex]?.unitDetails?.buyerDetails?.address,
            country:
              leadData?.country || saleDetails[saleIndex]?.unitDetails?.buyerDetails?.country,
            state: leadData?.state || saleDetails[saleIndex]?.unitDetails?.buyerDetails?.state,
            city: leadData?.city || saleDetails[saleIndex]?.unitDetails?.buyerDetails?.city,
            zipCode:
              leadData?.zipCode || saleDetails[saleIndex]?.unitDetails?.buyerDetails?.zipCode,
          },
          saleDetails: {
            ...saleDetails[saleIndex]?.unitDetails?.saleDetails,
            buyerRealtor:
              saleData?.buyerRealtor ||
              saleDetails[saleIndex]?.unitDetails?.saleDetails[saleIndex]?.buyerRealtor,
            saleSpace:
              saleData?.saleSpace ||
              saleDetails[saleIndex]?.unitDetails?.saleDetails[saleIndex]?.saleSpace,
            unitNo: saleData?.unitNo || saleDetails[saleIndex]?.unitDetails?.saleDetails?.unitNo,
            salePrice:
              saleData?.salePrice || saleDetails[saleIndex]?.unitDetails?.saleDetails?.salePrice,
            buyerSaleCommission:
              saleData?.buyerSaleCommission ||
              saleDetails[saleIndex]?.unitDetails?.saleDetails?.buyerSaleCommission,
            sellerSaleCommission:
              saleData?.sellerSaleCommission ||
              saleDetails[saleIndex]?.unitDetails?.saleDetails?.sellerSaleCommission,
            closingCost:
              saleData?.closingCost ||
              saleDetails[saleIndex]?.unitDetails?.saleDetails?.closingCost,
            titlePolicyCost:
              saleData?.titlePolicyCost ||
              saleDetails[saleIndex]?.unitDetails?.saleDetails?.titlePolicyCost,
            comments:
              saleData?.comments || saleDetails[saleIndex]?.unitDetails?.saleDetails?.comments,
          },
        },
      };

      if (buyerDetails && initialValues) {
        await addSaleDetails(updatedObject);
      } else {
        await addSaleDetails(finalObject);
        setSaleDetailsList((prevList) => [...prevList, finalObject]);
      }
    } catch (error) {
      notify.error(`Error in processing Sale ${error}`);
    } finally {
      setIsSubmitting(false);
      formik.resetForm();
    }
  };

  return (
    <>
      <Modal open={open && !showSaleDetails}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '35%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY: 'auto',
            maxHeight: '90vh',
          }}
        >
          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>Sale Details 1/2</Title>
            <IconButton onClick={handleClose}>
              <CloseIcon
                style={{
                  fontSize: 18,
                  color: '#272937',
                }}
              />
            </IconButton>
          </Box>

          <ModalDivider />

          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label='Buyer Name'
                  name='buyerName'
                  size='small'
                  fullWidth
                  value={formik.values.buyerName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.buyerName && Boolean(formik.errors.buyerName)}
                  helperText={formik.touched.buyerName && formik.errors.buyerName}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label='Contact Person'
                  name='buyerContactPerson'
                  size='small'
                  fullWidth
                  value={formik.values.buyerContactPerson}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.buyerContactPerson && Boolean(formik.errors.buyerContactPerson)
                  }
                  helperText={formik.touched.buyerContactPerson && formik.errors.buyerContactPerson}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label='Email'
                  name='email'
                  size='small'
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name='phoneNo'
                  label='Phone Number'
                  size='small'
                  fullWidth
                  value={formik.values.phoneNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                  helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                />
              </Grid>

              <Grid item xs={12}>
                <StandaloneSearchBox
                  onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                  onUnmount={() => (searchInputRef.current = null)}
                  onPlacesChanged={() => handlePlacesChanged(formik.setValues)}
                >
                  <TextField
                    label='Address'
                    name='address'
                    size='small'
                    fullWidth
                    value={formik.values.address}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (!value) {
                        formik.setValues((prevState) => ({
                          ...prevState,
                          search: '',
                          address: '',
                          country: '',
                          city: '',
                          state: '',
                          zipCode: '',
                          latitude: null,
                          longitude: null,
                        }));
                        if (markerRef.current) {
                          markerRef.current.setPosition(null);
                        }
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </StandaloneSearchBox>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label='City'
                  name='city'
                  size='small'
                  fullWidth
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label='State'
                  name='state'
                  size='small'
                  fullWidth
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label='Country'
                  name='country'
                  size='small'
                  fullWidth
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label='Zip Code'
                  name='zipCode'
                  size='small'
                  fullWidth
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
              </Grid>

              <ModalDivider />

              <Grid item xs={12} textAlign='center' display='flex' justifyContent='space-between'>
                <Button variant='outlined' color='error' onClick={handleClose}>
                  Cancel
                </Button>
                <Button type='submit' variant='contained'>
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      {showSaleDetails && (
        <SaleDetailsForm
          open={showSaleDetails}
          saleinfo={initialValues}
          onClose2={() => setShowSaleDetails(false)}
          onClose1={onClose}
          onSave={(saleData) => {
            handleSaveSubmit(saleData);
          }}
          selectedUnit={selectedUnit}
          fetchSaleDetails={fetchSaleDetails}
        />
      )}

      {/* {saleDetailsList.map((sale, index) => (
                <LeadSaleCard key={index} saleDetails={sale} />
            ))} */}
    </>
  );
};

export default LeadSaleForm;
