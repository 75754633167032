import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Title } from 'app/common/Typography';
import styles from './modules/RequestActions.module.css';
import { useState } from 'react';
import { handleAxiosError } from 'app/utils/helpers';

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

function RequestActions({ request, respondToRequest }) {
  const [state, setState] = useState({
    openModal: false,
    isSubmitting: false,
    modalAction: null,
  });

  const handleOpenModal = (action) => {
    setState((prevState) => ({
      ...prevState,
      openModal: true,
      modalAction: action,
    }));
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      openModal: false,
    }));
  };

  const handleYesProceed = async () => {
    try {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
      await respondToRequest(request, state.modalAction);
      closeModal();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  };

  return (
    <>
      <Box className={styles.respondButtons}>
        <LoadingButton
          className={`${styles.button} ${styles.declineButton}`}
          variant='contained'
          color='error'
          onClick={() => handleOpenModal('decline')}
        >
          Decline
        </LoadingButton>
        <LoadingButton
          className={`${styles.button} ${styles.acceptButton}`}
          variant='contained'
          color='success'
          onClick={() => handleOpenModal('accept')}
        >
          Accept
        </LoadingButton>
      </Box>
      <Modal open={state.openModal} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <Box
            sx={{
              py: 1,
              px: 2,
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Title>{state.modalAction}</Title>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Box>
          <ModalDivider />
          <Typography sx={{ p: 3, fontSize: '1rem', fontWeight: '450' }}>
            Are you sure want to {state.modalAction} this request?
          </Typography>
          <ModalDivider />
          <Box
            sx={{
              justifyContent: 'flex-end',
              gap: 2,
              display: 'flex',
              p: 2,
            }}
          >
            <LoadingButton color='error' variant='outlined' onClick={closeModal}>
              Cancel
            </LoadingButton>
            <LoadingButton
              variant='contained'
              color='primary'
              onClick={handleYesProceed}
              loading={state.isSubmitting}
            >
              Yes Proceed
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default RequestActions;
