import React from 'react';
import { Box, Button, Grid, TextField, Modal, IconButton, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useProject } from 'app/contexts/ProjectContext';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import notify from 'app/utils/notify';
import { getAllLeaseDetais } from 'app/contexts/leadActions';
import { StandaloneSearchBox } from '@react-google-maps/api';

const ModalDivider = styled('div')({
  height: '1px',
  marginBottom: '5px',
  backgroundColor: '#D5D5DD',
});

// Validation Schema using Yup
const validationSchema = Yup.object({
  LLCName: Yup.string().required('LLC Name is required'),
  LLCNumber: Yup.string().required('LLC Number is required'),
  LLCPhoneNumber: Yup.string().required('LLC Phone Number is required'),
  LLCEmail: Yup.string().email('Invalid email format').required('LLC Email is required'),
  LLCAddress1: Yup.string().required('LLC Address 1 is required'),
  LLCAddress2: Yup.string(),
  LLCCity: Yup.string().required('LLC City is required'),
  LLCState: Yup.string().required('LLC State is required'),
  LLCCountry: Yup.string().required('LLC Country is required'),
  LLCZIPCode: Yup.string().required('LLC ZIP Code is required'),

  contactPerson: Yup.string().required('Contact Person is required'),
  tenantEmail: Yup.string().email('Invalid email format').required('Email is required'),
  tenantPhoneNo: Yup.string().required('Phone Number is required'),
  Address1: Yup.string().required('Address 1 is required'),
  Address2: Yup.string().optional(),
  City: Yup.string().required('City is required'),
  State: Yup.string().required('State is required'),
  Country: Yup.string().required('Country is required'),
  ZIPCode: Yup.string().required('ZIP Code is required'),
});

const LeadAddLeaseForm = ({
  openModal,
  handleCancel,
  leaseDetails,
  selectedBuilding,
  selectedUnit,
  setLeaseDetailsList,
  tenantDetails1,
  selectedunitIndex,
  buildingIndex,
  leaseIndex,
}) => {
  const { addLeaseDetails, uploadDocuments, leasesales } = useProject();
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { projectId } = useParams();
  const [leaseDetails1, setLeaseDetails1] = React.useState([]);
  const mapRef = React.useRef(null);
  const markerRef = React.useRef(null);
  const searchInputRef = React.useRef(null);

  const mapRef1 = React.useRef(null);
  const markerRef1 = React.useRef(null);
  const searchInputRef1 = React.useRef(null);

  const parseAddressComponents = (place) => {
    const components = place.address_components;
    const addressData = {};

    components.forEach((component) => {
      const types = component.types;
      if (types.includes('locality')) {
        addressData.LLCCity = component.long_name; // City
      }
      if (types.includes('administrative_area_level_1')) {
        addressData.LLCState = component.short_name; // State
      }
      if (types.includes('country')) {
        addressData.LLCCountry = component.long_name; // Country
      }
      if (types.includes('postal_code')) {
        addressData.LLCZIPCode = component.long_name; // Zip Code
      }
    });

    return addressData;
  };

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const addressData = parseAddressComponents(place);
    const shortAddress = place.formatted_address.split(',')[0];

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
      LLCAddress1: shortAddress,
    }));

    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    }

    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  const parseAddressComponents1 = (place) => {
    const components = place.address_components;
    const addressData = {};

    components.forEach((component) => {
      const types = component.types;
      if (types.includes('locality')) {
        addressData.City = component.long_name; // City
      }
      if (types.includes('administrative_area_level_1')) {
        addressData.State = component.short_name; // State
      }
      if (types.includes('country')) {
        addressData.Country = component.long_name; // Country
      }
      if (types.includes('postal_code')) {
        addressData.ZIPCode = component.long_name; // Zip Code
      }
    });

    return addressData;
  };

  const convertToUTC = (date) => {
    const localDate = new Date(date);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
    return utcDate.toISOString();
  };
  // const handlePlacesChanged1 = (setValues) => {
  //   if (!searchInputRef1.current) {
  //     console.error('Search Box has not been initialized yet');
  //     return;
  //   }

  //   const places = searchInputRef1.current.getPlaces();
  //   if (!places || places.length === 0) {
  //     console.error('No places found');
  //     return;
  //   }

  //   const [place] = places;
  //   if (!place || !place.geometry) {
  //     console.error('Place has no geometry');
  //     return;
  //   }

  //   const lat = place.geometry.location.lat();
  //   const lng = place.geometry.location.lng();

  //   const addressData = parseAddressComponents1(place);
  //   const shortAddress = place.formatted_address.split(',')[0];

  //   setValues((prevState) => ({
  //     ...prevState,
  //     ...addressData,
  //     Address1: shortAddress,
  //   }));

  //   if (markerRef1.current) {
  //     markerRef1.current.setPosition({ lat, lng });
  //   }

  //   if (mapRef1.current) {
  //     mapRef1.current.panTo({ lat, lng });
  //   }
  // };

  const handlePlacesChanged1 = (setValues) => {
    const [place] = searchInputRef1.current.getPlaces();
    if (!place) return;

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const addressData = parseAddressComponents1(place);
    const shortAddress = place.formatted_address.split(',')[0];

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
      Address1: shortAddress,
    }));

    if (markerRef1.current) {
      markerRef1.current.setPosition({ lat, lng });
    }

    if (mapRef1.current) {
      mapRef1.current.panTo({ lat, lng });
    }
  };

  React.useEffect(() => {
    if (projectId) {
      fetchLeaseDetails();
    }
  }, [projectId]);

  const fetchLeaseDetails = async () => {
    try {
      const leaseData = await getAllLeaseDetais(projectId);
      const filteredSales = leaseData.filter(
        (sale) => sale?.unitId === leasesales[buildingIndex]?.units[selectedunitIndex]?.unitId,
      );
      setLeaseDetails1(filteredSales);
    } catch (error) {
      notify.error(error);
    }
  };

  const initialValues = {
    LLCName: tenantDetails1?.llcDetails?.llcName || '',
    LLCNumber: tenantDetails1?.llcDetails?.llcNumber || '',
    LLCPhoneNumber: tenantDetails1?.llcDetails?.phoneNo || '',
    LLCEmail: tenantDetails1?.llcDetails?.email || '',
    LLCAddress1: tenantDetails1?.llcDetails?.address || '',
    LLCAddress2: tenantDetails1?.llcDetails?.LLCAddress2 || '',
    LLCCity: tenantDetails1?.llcDetails?.city || '',
    LLCState: tenantDetails1?.llcDetails?.state || '',
    LLCCountry: tenantDetails1?.llcDetails?.country || '',
    LLCZIPCode: tenantDetails1?.llcDetails?.zipCode || '',

    contactPerson: tenantDetails1?.contactPerson || '',
    tenantEmail: tenantDetails1?.email || '',
    tenantPhoneNo: tenantDetails1?.phoneNo || '',
    Address1: tenantDetails1?.address || '',
    Address2: tenantDetails1?.Address2 || '',
    City: tenantDetails1?.city || '',
    State: tenantDetails1?.state || '',
    Country: tenantDetails1?.country || '',
    ZIPCode: tenantDetails1?.zipCode || '',
  };

  const handleSave = async (values) => {
    const data = new FormData();
    const leaseDocumentids = [];

    for (let index = 0; index < leaseDetails?.leaseDocuments.length; index++) {
      const doc = leaseDetails?.leaseDocuments[index];

      if (doc.file instanceof File) {
        data.append(`docReq[${index}].file`, doc.file);
        data.append(`docReq[${index}].docName`, doc.docName);
        data.append(`docReq[${index}].type`, 'Lease Documents');
      } else {
        leaseDocumentids.push(leaseDetails?.leaseDocuments[index]);
      }
    }

    try {
      setSubmitting(true);
      if (data.has('docReq[0].file')) {
        const uploadResponse = await uploadDocuments(data);
        const uploadedDocumentsIds = uploadResponse.data
          .match(/Document IDs: \[([^\]]+)\]/)[1]
          .split(', ');
        leaseDocumentids.push(...uploadedDocumentsIds);
      }
      const tenantDetails = {
        contactPerson: values.contactPerson,
        email: values.tenantEmail,
        phoneNo: values.tenantPhoneNo,
        address: values.Address1,
        country: values.Country,
        state: values.State,
        city: values.City,
        zipCode: values.ZIPCode,
        llcDetails: {
          llcName: values.LLCName,
          llcNumber: values.LLCNumber,
          email: values.LLCEmail,
          phoneNo: values.LLCPhoneNumber,
          address: values.LLCAddress1,
          country: values.LLCCountry,
          state: values.LLCState,
          city: values.LLCCity,
          zipCode: values.LLCZIPCode,
        },
      };

      const finalObject = {
        buildingName: selectedBuilding?.buildingName,
        buildingId: selectedBuilding?.id,
        unitId: selectedUnit?.unitId,
        unitDetails: {
          tenantDetails: tenantDetails,
          leaseDetails: {
            ...leaseDetails,
            leaseDocuments: leaseDocumentids,
          },
        },
      };

      const updatedObject = {
        buildingName: leaseDetails1[leaseIndex]?.buildingName,
        buildingId: leaseDetails1[leaseIndex]?.buildingId,
        unitId: leaseDetails1[leaseIndex]?.unitId,
        id: leaseDetails1[leaseIndex]?.id,
        unitDetails: {
          tenantDetails: {
            ...leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails,
            contactPerson:
              values.contactPerson ||
              leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.contactPerson,
            email:
              values.tenantEmail ||
              leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.tenantEmail,
            phoneNo:
              values.tenantPhoneNo ||
              leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.tenantPhoneNo,
            address:
              values.Address1 || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.Address1,
            country:
              values.Country || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.Country,
            state: values.State || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.State,
            city: values.City || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.City,
            zipCode:
              values.ZIPCode || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.ZIPCode,
            llcDetails: {
              ...leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails,
              llcName:
                values.LLCName ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCName,
              llcNumber:
                values.LLCNumber ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCNumber,
              email:
                values.LLCEmail ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCEmail,
              phoneNo:
                values.LLCPhoneNumber ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCPhoneNumber,
              address:
                values.LLCAddress1 ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCAddress1,
              country:
                values.LLCCountry ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCCountry,
              state:
                values.LLCState ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCState,
              city:
                values.LLCCity ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCCity,
              zipCode:
                values.LLCZIPCode ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCZIPCode,
            },
          },
          leaseDetails: {
            ...leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails,
            leaseSpace:
              leaseDetails?.leaseSpace ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseSpace,
            rentSqFtPerYear:
              leaseDetails?.rentSqFtPerYear ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.rentSqFtPerYear,
            leaseTerm:
              leaseDetails?.leaseTerm ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseTerm,
            rentIncreasePerYear:
              leaseDetails?.rentIncreasePerYear ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.rentIncreasePerYear,
            leaseExecutionDate:
              convertToUTC(leaseDetails?.leaseExecutionDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseExecutionDate,
            leaseFixturizationDate:
              convertToUTC(leaseDetails?.leaseFixturizationDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseFixturizationDate,
            leaseStartDate:
              convertToUTC(leaseDetails?.leaseStartDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseStartDate,
            leaseEndDate:
              convertToUTC(leaseDetails?.leaseEndDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseEndDate,
            currentMonthRent:
              leaseDetails?.currentMonthRent ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.currentMonthRent,
            nnn: leaseDetails?.nnn || leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.nnn,
            // securityDeposit: leaseDetails?.securityDeposit || leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.securityDeposit,
            // rentPerYear: leaseDetails?.leaseDetails || leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseDetails,
            securityDeposit:
              leaseDetails?.securityDeposit ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.securityDeposit,
            firstMonthRentDate:
              convertToUTC(leaseDetails?.firstMonthRentDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.firstMonthRentDate,
            realtorCommission:
              leaseDetails?.realtorCommission ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.realtorCommission,
            realtorRebate:
              leaseDetails?.realtorRebate ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.realtorRebate,
            leaseDocuments:
              leaseDocumentids ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseDocuments,
            comments:
              leaseDetails?.comments ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.comments,
            rentCommencementDate: '2024-06-01T10:30:00',
            freeRentMonths: 5,
            rentPerYear: '100',
          },
        },
      };

      if (tenantDetails1) {
        await addLeaseDetails(updatedObject);
        handleCancel();
      } else {
        await addLeaseDetails(finalObject);
        setLeaseDetailsList((prevList) => [...prevList, finalObject]);
        handleCancel();
      }
    } catch (error) {
      notify.error(`Error in Processing the Lease:${error}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={openModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '40%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: 'auto',
          height: '90vh',
        }}
      >
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Title>{'Add Tenant & LLC Details'}</Title>
          <IconButton onClick={handleCancel}>
            <CloseIcon style={{ fontSize: 18, color: '#272937' }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', marginLeft: '15px' }} mb={2}>
          <Typography sx={{ fontWeight: '500', fontSize: '16px', color: '#272937' }}>
            Tenant & LLC Details
          </Typography>
        </Box>
        <ModalDivider />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setValues,
            setFieldValue,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form>
              <Grid container spacing={3} p={2}>
                {/* LLC Details Section */}
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC Name'
                    name='LLCName'
                    fullWidth
                    error={touched.LLCName && Boolean(errors.LLCName)}
                    helperText={<ErrorMessage name='LLCName' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC Number'
                    name='LLCNumber'
                    fullWidth
                    error={touched.LLCNumber && Boolean(errors.LLCNumber)}
                    helperText={<ErrorMessage name='LLCNumber' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC Phone Number'
                    name='LLCPhoneNumber'
                    fullWidth
                    error={touched.LLCPhoneNumber && Boolean(errors.LLCPhoneNumber)}
                    helperText={<ErrorMessage name='LLCPhoneNumber' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC Email'
                    name='LLCEmail'
                    fullWidth
                    error={touched.LLCEmail && Boolean(errors.LLCEmail)}
                    helperText={<ErrorMessage name='LLCEmail' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StandaloneSearchBox
                    onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                    onUnmount={() => (searchInputRef.current = null)}
                    onPlacesChanged={() => handlePlacesChanged(setValues)}
                  >
                    <TextField
                      sx={{
                        marginTop: '0',
                        '& .MuiOutlinedInput-root': {
                          height: '36px',
                          padding: '0px',
                          '& input': {
                            padding: '8px 12px',
                          },
                        },
                        '& .MuiInputBase-input': {
                          height: 'auto',
                        },
                      }}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      type='search'
                      name='LLCAddress1'
                      id='standard-basic'
                      placeholder='LLC Address1'
                      value={values?.LLCAddress1}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (!value) {
                          setValues((prevState) => ({
                            ...prevState,
                            search: '',
                            LLCAddress1: '',
                            LLCCountry: '',
                            LLCCity: '',
                            LLCState: '',
                            LLCZIPCode: '',
                            latitude: null,
                            longitude: null,
                          }));
                          if (markerRef.current) {
                            markerRef.current.setPosition(null);
                          }
                        }
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      helperText={touched.LLCAddress1 && errors.LLCAddress1}
                      error={Boolean(errors.LLCAddress1 && touched.LLCAddress1)}
                      disabled={isSubmitting}
                    />
                  </StandaloneSearchBox>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC Address 2'
                    name='LLCAddress2'
                    fullWidth
                    error={touched.LLCAddress2 && Boolean(errors.LLCAddress2)}
                    helperText={<ErrorMessage name='LLCAddress2' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC City'
                    name='LLCCity'
                    fullWidth
                    error={touched.LLCCity && Boolean(errors.LLCCity)}
                    helperText={<ErrorMessage name='LLCCity' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC State'
                    name='LLCState'
                    fullWidth
                    error={touched.LLCState && Boolean(errors.LLCState)}
                    helperText={<ErrorMessage name='LLCState' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC Country'
                    name='LLCCountry'
                    fullWidth
                    error={touched.LLCCountry && Boolean(errors.LLCCountry)}
                    helperText={<ErrorMessage name='LLCCountry' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC ZIP Code'
                    name='LLCZIPCode'
                    fullWidth
                    error={touched.LLCZIPCode && Boolean(errors.LLCZIPCode)}
                    helperText={<ErrorMessage name='LLCZIPCode' />}
                  />
                </Grid>

                {/* Tenant Details Section */}
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='Contact Person'
                    name='contactPerson'
                    fullWidth
                    error={touched.contactPerson && Boolean(errors.contactPerson)}
                    helperText={<ErrorMessage name='contactPerson' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='Email'
                    name='tenantEmail'
                    fullWidth
                    error={touched.tenantEmail && Boolean(errors.tenantEmail)}
                    helperText={<ErrorMessage name='tenantEmail' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='Phone Number'
                    name='tenantPhoneNo'
                    fullWidth
                    error={touched.tenantPhoneNo && Boolean(errors.tenantPhoneNo)}
                    helperText={<ErrorMessage name='tenantPhoneNo' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StandaloneSearchBox
                    onLoad={(searchBox) => (searchInputRef1.current = searchBox)}
                    onUnmount={() => (searchInputRef1.current = null)}
                    onPlacesChanged={() => handlePlacesChanged1(setValues)}
                  >
                    <TextField
                      sx={{
                        marginTop: '0',
                        '& .MuiOutlinedInput-root': {
                          height: '36px',
                          padding: '0px',
                          '& input': {
                            padding: '8px 12px',
                          },
                        },
                        '& .MuiInputBase-input': {
                          height: 'auto',
                        },
                      }}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      type='search'
                      name='Address1'
                      id='standard-basic'
                      placeholder='Address1'
                      value={values?.Address1}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (!value) {
                          setValues((prevState) => ({
                            ...prevState,
                            search: '',
                            Address1: '',
                            Country: '',
                            City: '',
                            State: '',
                            ZIPCode: '',
                            latitude: null,
                            longitude: null,
                          }));
                          if (markerRef1.current) {
                            markerRef1.current.setPosition(null);
                          }
                        }
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      helperText={touched.Address1 && errors.Address1}
                      error={Boolean(errors.Address1 && touched.Address1)}
                      disabled={isSubmitting}
                    />
                  </StandaloneSearchBox>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='Address 2'
                    name='Address2'
                    fullWidth
                    error={touched.Address2 && Boolean(errors.Address2)}
                    helperText={<ErrorMessage name='Address2' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='City'
                    name='City'
                    fullWidth
                    error={touched.City && Boolean(errors.City)}
                    helperText={<ErrorMessage name='City' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='State'
                    name='State'
                    fullWidth
                    error={touched.State && Boolean(errors.State)}
                    helperText={<ErrorMessage name='State' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='Country'
                    name='Country'
                    fullWidth
                    error={touched.Country && Boolean(errors.Country)}
                    helperText={<ErrorMessage name='Country' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='ZIP Code'
                    name='ZIPCode'
                    fullWidth
                    error={touched.ZIPCode && Boolean(errors.ZIPCode)}
                    helperText={<ErrorMessage name='ZIPCode' />}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  textAlign='center'
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <LoadingButton
                    variant='outlined'
                    color='error'
                    disabled={isSubmitting}
                    onClick={handleCancel}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default LeadAddLeaseForm;
