import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { useProject } from 'app/contexts/ProjectContext';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { formatCurrency } from 'app/utils/helpers';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const InfoBox = styled(Box)({
  flex: '1 1 calc(25% - 10px)', // Four items per row with some spacing
  marginBottom: '20px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: '#4F90F0',
});

const LeadsLenderDetails = () => {
  const { isOnHoldOrCancelled, closing } = useProject();

  const infoPairs = [
    { key: 'LENDER NAME', value: closing?.lenderDetails?.lenderName },
    { key: 'CONTACT NUMBER', value: closing?.lenderDetails?.lenderContactNumber },
    { key: 'EMAIL ADDRESS', value: closing?.lenderDetails?.lenderEmail },
    { key: 'LEASE TERM', value: closing?.lenderDetails?.leaseTerm },
    {
      key: 'DOWN PAYMENT',
      value: formatCurrency(closing?.lenderDetails?.downPayment),
    },
    {
      key: 'LOAN AMOUNT',
      value: formatCurrency(closing?.lenderDetails?.loanAmount),
    },
    { key: 'INTEREST RATE', value: closing?.lenderDetails?.interestRate },
  ];

  return (
    <div className='viewlead'>
      <SubtitleDivider />
      <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title>Lender Details :</Title>
          <Button
            // onClick={handleOpen}
            style={{ color: '#4F90F0', fontSize: '14px' }}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
          </Button>
        </Box>
        <InfoContainer>
          {infoPairs.map(({ key, value }, index) => (
            <InfoBox key={index}>
              <Key>{key}</Key>
              <ValueContainer>
                <Value className='basicvalues'>{value ?? ''}</Value>
              </ValueContainer>
            </InfoBox>
          ))}
        </InfoContainer>
      </Box>
    </div>
  );
};

export default LeadsLenderDetails;
