import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField } from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { parseAddressComponents } from 'app/utils/helpers';
import notify from 'app/utils/notify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '10px',
  marginBottom: '1em',
  paddingRight: '10px',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const Inputlabel = styled('div')({
  marginBottom: '5%',
});

const SearchBoxContainer = styled('div')({
  width: '100%',
  '& input': {
    padding: '0.3em 0.5em',
    height: '2.710em',
    width: '100%',
    boxSizing: 'border-box',
  },
});

const validationSchema = Yup.object().shape({
  llcName: Yup.string().required('LLC Name is required'),
  contractName: Yup.string().required('Contractor Name is required'),
  emailId: Yup.string()
    .email('Invalid email format')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email domain')
    .required('Email is required'),
  phoneNum: Yup.string()
    .required('Phone Number is required')
    .min(10, 'Phone Number must be at least 10 digits long'),
  address: Yup.string()
    .required('Address is required')
    .min(5, 'Address must be at least 5 characters long')
    .max(100, 'Address must be 100 characters or less'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  zipCode: Yup.string().required('ZIP Code is required'),
});

const GeneralContractorDetailsModal = ({ contractors, contractor, handleCloseModal }) => {
  const { updateGC, updateRowGCDetails } = useProject();
  const mapRef = React.useRef(null);
  const markerRef = React.useRef(null);
  const searchInputRef = React.useRef(null);

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const addressData = parseAddressComponents(place);
    const shortAddress = place.formatted_address.split(',')[0];

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
      address: shortAddress,
    }));

    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    }

    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  const initialValues = {
    llcName: contractor?.contractorDetails?.llcName || '',
    contractName: contractor?.contractorDetails?.contractName || '',
    emailId: contractor?.contractorDetails?.emailId || '',
    phoneNum: contractor?.contractorDetails?.phoneNum || '',
    address: contractor?.contractorDetails?.address || '',
    country: contractor?.contractorDetails?.country || '',
    state: contractor?.contractorDetails?.state || '',
    city: contractor?.contractorDetails?.city || '',
    zipCode: contractor?.contractorDetails?.zipCode || '',
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const contractorDetails = {
        llcName: values.llcName,
        contractName: values.contractName,
        phoneNum: values.phoneNum,
        emailId: values.emailId,
        address: values.address,
        country: values.country,
        state: values.state,
        city: values.city,
        zipCode: values.zipCode,
      };
      const postData = {
        contractorDetails,
        gcManager: contractor?.gcManager || [],
        gcSiteManager: contractor?.gcSiteManager || [],
        changeOrders: contractor?.changeOrders || [],
        payments: contractor?.payments || [],
      };

      const updateData = {
        ...contractor,
        contractorDetails,
      };

      if (contractor) {
        await updateRowGCDetails(updateData?.projectId, updateData?.id, updateData);
      } else {
        await updateGC(postData);
      }
      handleCloseModal();
    } catch (error) {
      notify.error(`Error processing the contractor: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{contractor ? 'Edit' : 'Add'} General Contractor Details</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: '#272937',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setValues,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>LLC NAME</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='llcName'
                        value={values.llcName}
                        onChange={handleChange}
                        placeholder='e.g. : John Doe LLC'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.llcName && errors.llcName)}
                        helperText={touched.llcName && errors.llcName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>CONTRACTOR NAME</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='contractName'
                        value={values.contractName}
                        onChange={handleChange}
                        placeholder='e.g. : John Doe'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.contractName && errors.contractName)}
                        helperText={touched.contractName && errors.contractName}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Email Address</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='emailId'
                        value={values.emailId}
                        onChange={handleChange}
                        placeholder='e.g. : john.doe@example.com'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.emailId && errors.emailId)}
                        helperText={touched.emailId && errors.emailId}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Phone Number</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='tel'
                        name='phoneNum'
                        value={values.phoneNum}
                        onChange={handleChange}
                        placeholder='e.g. 9876543210'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.phoneNum && errors.phoneNum)}
                        helperText={touched.phoneNum && errors.phoneNum}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Address</InputLabel>
                      </Inputlabel>
                      <SearchBoxContainer>
                        <StandaloneSearchBox
                          onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                          onUnmount={() => (searchInputRef.current = null)}
                          onPlacesChanged={() => handlePlacesChanged(setValues)}
                        >
                          <TextField
                            sx={{ marginTop: '0px', height: '0px' }}
                            fullWidth
                            margin='normal'
                            variant='outlined'
                            type='search'
                            name='address'
                            id='standard-basic'
                            placeholder='Search With Place, Landmark'
                            value={values?.address}
                            onChange={(e) => {
                              const { value } = e.target;

                              if (!value) {
                                setValues((prevState) => ({
                                  ...prevState,
                                  search: '',
                                  address: '',
                                  country: '',
                                  city: '',
                                  state: '',
                                  zipCode: '',
                                  latitude: null,
                                  longitude: null,
                                }));
                                if (markerRef.current) {
                                  markerRef.current.setPosition(null);
                                }
                              }
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            helperText={touched.address && errors.address}
                            error={Boolean(errors.address && touched.address)}
                            disabled={isSubmitting}
                          />
                        </StandaloneSearchBox>
                      </SearchBoxContainer>
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>City</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='city'
                        value={values.city}
                        onChange={handleChange}
                        placeholder='e.g., Texas'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>State</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='state'
                        value={values.state}
                        onChange={handleChange}
                        placeholder='e.g., Texas'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Zip Code</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='zipCode'
                        value={values.zipCode}
                        onChange={handleChange}
                        placeholder='e.g. 123456'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.zipCode && errors.zipCode)}
                        helperText={touched.zipCode && errors.zipCode}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Country</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='country'
                        value={values.country}
                        onChange={handleChange}
                        placeholder='e.g., Texas'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                      />
                    </Grid>
                  </Grid>
                </Container>
                <Box
                  marginLeft={1.5}
                  marginRight={1}
                  sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <LoadingButton
                    className='cancelButton'
                    color='error'
                    variant='outlined'
                    onClick={handleCloseModal}
                    style={{ boxShadow: 'none' }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    style={{ boxShadow: 'none', marginRight: '10px' }}
                    type='submit'
                    loading={isSubmitting}
                  >
                    {contractor ? 'Update' : 'Save'}
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </div>
  );
};

export default GeneralContractorDetailsModal;
