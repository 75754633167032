import React, { useState } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Container,
  TableRow,
  Table,
  Modal,
  Button,
  Icon
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span } from 'app/common/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
import CLPaymentDetailsModal from './CLPaymentDetailsModal';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const ConstructionLenderPaymentDetails = ({ gcpaymentData, clPaymentsIndex }) => {
  // const [gcpayments, setGcpayments1 ] = useState(gcpaymentData?.payments)
  const [open, setOpen] = React.useState(false);
  const [selectedPD, setSelectedPD] = React.useState(null);

  const handleClickOpen = () => {
    setSelectedPD(null);
    setOpen(true);
  }

  const handleClickUpdate = (payment) => {
    setSelectedPD(payment);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <SubtitleDivider />
      {
        gcpaymentData[clPaymentsIndex]?.loanPayments?.length === 0 ? (<>
          <Box>
            <Title sx={{ marginLeft: '2%' }}>Loan Payment Details</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }} >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontWeight: '600' }} variant="body1" color="#272937" gutterBottom>
                  No details added
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  It seems you have not added any details related to Loan Payment Details.
                </Typography>
                <Button onClick={handleClickOpen} variant="contained" color="primary">
                  Add Details
                </Button>
              </Box>
            </Container>
            <Modal open={open} onClose={handleClose}>
              <CLPaymentDetailsModal PaymentDetails={selectedPD} handleCloseModal={handleClose} gcpaymentsData={gcpaymentData} clPaymentsIndex={clPaymentsIndex} />
            </Modal>
          </Box>
        </>) : (<>
          <Box
            marginLeft={3}
            marginRight={3}
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}PaymentDetails
          >
            <Title sx={{ marginLeft: '0px' }}>Loan Payment Details</Title>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Button onClick={handleClickOpen}>+ ADD</Button>
            </Box>
          </Box>
          <Modal open={open} onClose={handleClose}>
            <CLPaymentDetailsModal PaymentDetails={selectedPD} handleCloseModal={handleClose} gcpaymentsData={gcpaymentData} clPaymentsIndex={clPaymentsIndex} />
          </Modal>
          <Box
            marginLeft={3}
            marginRight={3}
            marginTop={1}
            marginBottom={1}
            overflow='auto' border={'2px solid #E5E5E7'}
          >
            <ProductTable marginLeft={3} marginRight={3}>
              <TableHead bgcolor='#F2F9FC'>
                <TableRow color='#64748B' >
                  <TableCell sx={{ px: 2, color: '#64748B' }}>S.NO.</TableCell>
                  <TableCell sx={{ px: 0, color: '#64748B' }}>LOAN MONTHLY INCOME</TableCell>
                  <TableCell sx={{ px: 0, color: '#64748B' }}>LOAN PAYMENT</TableCell>
                  <TableCell sx={{ px: 0, color: '#64748B' }}>PAID BY</TableCell>
                  <TableCell sx={{ px: 0, width: '5%', color: '#64748B' }}></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  gcpaymentData[clPaymentsIndex]?.loanPayments?.map((payment, index) => (
                    <TableRow hover>
                      <TableCell
                        align='left'
                        sx={{
                          px: 2,
                          textTransform: 'capitalize',
                          fontWeight: 500,
                          fontSize: '14px',
                          color: '#272937',
                        }}
                      >
                        {`${(index + 1).toString().padStart(2, '0')}`}
                      </TableCell>

                      <TableCell align='left' sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}>
                        {payment.loanMonthlyPayment}
                      </TableCell>

                      <TableCell align='left' sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}>
                        {payment.loanPayment}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {payment.paidBy}
                      </TableCell>

                      <TableCell
                        sx={{ color: '#272937' }}
                        align='right'
                      >
                        <SideNavMenu menuButton={<UserMenu>
                          <MoreVertIcon />
                        </UserMenu>}>
                          <StyledItem onClick={() => handleClickUpdate(payment)}>
                            <Icon><UpdateIcon /></Icon>
                            <Span>Update</Span>
                          </StyledItem>
                        </SideNavMenu>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>

            </ProductTable>
          </Box></>)
      }
    </>
  )
}

export default ConstructionLenderPaymentDetails
