import { combineReducers } from 'redux';
import NavigationReducer from './NavigationReducer';
import NotificationReducer from './NotificationReducer';
import searchReducer from 'app/components/sidenav/searchReducer';
import ProjectCounterReducer from 'app/redux/reducers/ProjectCounterReducer';

const RootReducer = combineReducers({
  search: searchReducer,
  notifications: NotificationReducer,
  navigations: NavigationReducer,
  projectscount: ProjectCounterReducer,
});

export default RootReducer;
