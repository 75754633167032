import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Tooltip,
    IconButton,
    Link,
    Modal,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PaymentDetailsModal from './PaymentDetailsModal';
// import { getExpensesByInvoice } from 'app/contexts/leadActions';
import { ConstructionOutlined } from '@mui/icons-material';
import notify from 'app/utils/notify';

const PaymentDetailsView = ({ payment,gcPayment,gcpaymentIndex }) => {
    const [open, setOpen] = useState(false);
    const [disable, setDisable] = useState(payment?.paymentHistory?.length > 0 ? payment?.paymentHistory[payment?.paymentHistory.length - 1]?.balance : null);
    const [loading, setLoading] = useState(false);

React.useEffect(()=>{
    if(payment?.paymentHistory){
        setDisable(payment?.paymentHistory[payment?.paymentHistory.length-1]?.balance);
    }
},[payment?.paymentHistory])

    if (!payment) {
        return <Typography>No Payment data available</Typography>;
    }


    const formatDateTime = (isoString) => {
        const date = new Date(isoString);
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });


        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        return `${formattedDate}, ${formattedTime}`;
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box sx={{ width: '100%', marginTop: '1%' }}>
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
                <Typography>
                    (Invoice: {payment?.paymentId})
                </Typography>

        <Link component='button' disabled={disable === 0} sx={{
          marginRight: '1%',
          color: disable === 0 ? '#9e9e9e' : '#1e88e5',
          textDecoration: disable === 0 ? 'none' : 'underline',
          cursor: disable === 0 ? 'not-allowed' : 'pointer',
        }} onClick={handleClickOpen}>
          Update Payment
        </Link>
            </Box>

            {loading ? (
                <Typography variant='h6'>Loading Expense Details ...</Typography>
            ) : (payment?.paymentHistory.length > 0 ? (
                payment?.paymentHistory.map((GCpayment, index) => (
                    <Paper
                        elevation={3}
                        sx={{
                            p: 2,
                            width: '100%',
                            marginBottom: '1%',
                        }}
                        key={index + 1}>
                        <Typography color='#5E6176' align='center' bgcolor='#EBEAEA' fontWeight='500' width='155px' gutterBottom>
                            {formatDateTime(GCpayment?.updatedDate)}
                        </Typography>
                        <Grid container spacing={3} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={2}>
                                <Typography variant='body2' color='textSecondary'>
                                    TOTAL AMOUNT
                                </Typography>
                                <Typography variant='h6'>${GCpayment?.totalAmount.toFixed(2)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant='body2' color='textSecondary'>
                                    PAID AMOUNT
                                </Typography>
                                <Typography variant='h6' color='primary'>
                                    ${GCpayment?.amountPaid.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant='body2' color='textSecondary'>
                                    BALANCE AMOUNT
                                </Typography>
                                <Typography variant='h6' color='green'>
                                    {/* ${(GCpayment.totalAmount - GCpayment.amountPaid).toFixed(2)} */}
                                    ${(GCpayment?.balance).toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant='body2' color='textSecondary'>
                                    PAID BY
                                </Typography>
                                <Typography variant='h6'>{GCpayment?.paidBy}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant='body2' color='textSecondary'>
                                    PAID TO
                                </Typography>
                                <Typography variant='h6'>{GCpayment?.paidTo}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant='body2' color='textSecondary'>
                                    PAYMENT MODE
                                </Typography>
                                <Typography variant='h6'>{GCpayment?.paymentType}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                ))
            ) : (
                <Typography>No payment details available</Typography>
            ))}
            <Modal open={open} onClose={handleClose}>
                <PaymentDetailsModal handleCloseModal={handleClose} PaymentDetails={payment} gcpayments={gcPayment} gcpaymentIndex={gcpaymentIndex} />
            </Modal> 
        </Box>
    )
}

export default PaymentDetailsView
