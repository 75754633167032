const initialState = {
  lead: 0,
  newproject: 0,
  ongoing: 0,
  onhold: 0,
  cancelled: 0,
  completed: 0,
};

const ProjectCounterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COUNTER':
      return {
        ...state,
        ...initialState, // Ensure all initial state properties are included
        ...action.payload, // Override with actual values from payload
      };
    default:
      return state;
  }
};

export const setProjectCounterState = (value) => ({
  type: 'SET_COUNTER',
  payload: value,
});

export default ProjectCounterReducer;
