export const topBarHeight = 64;
export const sideNavWidth = 213;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const clientId = 'YOUR_CLIENT_ID.apps.googleusercontent.com'; //will be updated once we get this
export const googleMapsApiKey = 'AIzaSyCDUIkyDMVrgLh4Ax0Ki8DHcWAktv7qPTs';
export const DEFAULT_CENTER = { lat: 40.7128, lng: -74.006 }; //  New York City coords
export const dueDiligence = 'Due Diligence';
export const contractDocuments = 'Contract documents';
export const closingDocuments = 'Closing documents';
export const paymentOptions = [{ label: 'Cash' }, { label: 'Online' }, { label: 'Card' }];
export const expenseTypes = [{ label: 'Architect' }, { label: 'Engineer' }, { label: 'Construction' }];