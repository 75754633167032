import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { invitationsGetAll, respondToInvitation } from './requestsActions';
import { handleAxiosError } from 'app/utils/helpers';
import { fetchCountsOfProjects, fetchLeadsById } from './leadActions';
import { Loading } from 'app/components';
import { useDispatch } from 'react-redux';
import { setProjectCounterState } from 'app/redux/reducers/ProjectCounterReducer';

const RequestsContext = createContext();

const mockRequests = [
  {
    id: '66fab205fc0e3f1664e12bfb',
    email: 'maxwwe01@gmail.com',
    userId: null,
    projectId: '66faa27f6d7efc7abbab11f9',
    token: '0bb6e316-9f6e-4020-9aa3-bcc2323f041b',
    status: 'PENDING',
    expirationTime: '2024-10-01 14:13:25',
    createdTime: '2024-09-30 14:13:25',
    roles: ['Engineer'],
  },
  {
    id: '66fab205fc0e3f1664e12bfc',
    email: 'johndoe@example.com',
    userId: null,
    projectId: '66faa27f6d7efc7abbab11fa',
    token: '1cc7f427-a0f7-4131-bbb4-cdd3434g152c',
    status: 'PENDING',
    expirationTime: '2024-10-02 09:30:00',
    createdTime: '2024-10-01 09:30:00',
    roles: ['Designer'],
  },
  {
    id: '66fab205fc0e3f1664e12bfd',
    email: 'sarahsmith@company.com',
    userId: null,
    projectId: '66faa27f6d7efc7abbab11fb',
    token: '2dd8g538-b1g8-5242-ccc5-dee4545h263d',
    status: 'PENDING',
    expirationTime: '2024-10-03 16:45:30',
    createdTime: '2024-10-02 16:45:30',
    roles: ['Manager'],
  },
  {
    id: '66fab205fc0e3f1664e12bfe',
    email: 'mikebrown@gmail.com',
    userId: null,
    projectId: '66faa27f6d7efc7abbab11fc',
    token: '3ee9h649-c2h9-6353-ddd6-eff5656i374e',
    status: 'PENDING',
    expirationTime: '2024-10-04 11:20:15',
    createdTime: '2024-10-03 11:20:15',
    roles: ['Developer'],
  },
  {
    id: '66fab205fc0e3f1664e12bff',
    email: 'emilyjones@example.org',
    userId: null,
    projectId: '66faa27f6d7efc7abbab11fd',
    token: '4ff0i750-d3i0-7464-eee7-fgg6767j485f',
    status: 'PENDING',
    expirationTime: '2024-10-05 14:55:40',
    createdTime: '2024-10-04 14:55:40',
    roles: ['Tester'],
  },
  {
    id: '66fab205fc0e3f1664e12c00',
    email: 'alexwilson@company.net',
    userId: null,
    projectId: '66faa27f6d7efc7abbab11fe',
    token: '5gg1j861-e4j1-8575-fff8-ghh7878k596g',
    status: 'PENDING',
    expirationTime: '2024-10-06 08:10:55',
    createdTime: '2024-10-05 08:10:55',
    roles: ['Analyst'],
  },
  {
    id: '66fab205fc0e3f1664e12c01',
    email: 'olivialee@gmail.com',
    userId: null,
    projectId: '66faa27f6d7efc7abbab11ff',
    token: '6hh2k972-f5k2-9686-ggg9-hii8989l607h',
    status: 'PENDING',
    expirationTime: '2024-10-07 13:25:20',
    createdTime: '2024-10-06 13:25:20',
    roles: ['Designer', 'Developer'],
  },
  {
    id: '66fab205fc0e3f1664e12c02',
    email: 'danielclark@example.com',
    userId: null,
    projectId: '66faa27f6d7efc7abbab1200',
    token: '7ii3l083-g6l3-0797-hhh0-ijj9090m718i',
    status: 'PENDING',
    expirationTime: '2024-10-08 17:40:35',
    createdTime: '2024-10-07 17:40:35',
    roles: ['Manager', 'Analyst'],
  },
  {
    id: '66fab205fc0e3f1664e12c03',
    email: 'sophiaturner@company.org',
    userId: null,
    projectId: '66faa27f6d7efc7abbab1201',
    token: '8jj4m194-h7m4-1808-iii1-jkk0101n829j',
    status: 'PENDING',
    expirationTime: '2024-10-09 10:05:50',
    createdTime: '2024-10-08 10:05:50',
    roles: ['Engineer', 'Tester'],
  },
  {
    id: '66fab205fc0e3f1664e12c04',
    email: 'williamharris@gmail.com',
    userId: null,
    projectId: '66faa27f6d7efc7abbab1202',
    token: '9kk5n205-i8n5-2919-jjj2-kll1212o930k',
    status: 'PENDING',
    expirationTime: '2024-10-10 15:50:05',
    createdTime: '2024-10-09 15:50:05',
    roles: ['Developer', 'Analyst'],
  },
];

const initialState = {
  requests: [],
};

function requestsReducer(state, action) {
  switch (action.type) {
    case 'requests/set':
      return { ...state, requests: action.payload };
    case 'request/respond':
      return {
        ...state,
        requests: state.requests.filter((request) => request.id !== action.payload.invitationId),
      };
    default:
      return state;
  }
}

function RequestsProvider({ children }) {
  const [state, dispatch] = useReducer(requestsReducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true);
      try {
        const invitations = await invitationsGetAll();

        const invitationsWithDetails = await Promise.all(
          invitations.map(async (invitation) => {
            if (invitation.status === 'PENDING') {
              const projectBasicDetails = await fetchLeadsById(invitation.projectId);
              return { ...invitation, projectTitle: projectBasicDetails.projectTitle };
            } else {
              return invitation;
            }
          }),
        );

        dispatch({ type: 'requests/set', payload: invitationsWithDetails });
      } catch (error) {
        handleAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchRequests();
  }, []);

  const respondToRequest = async (request, action) => {
    const { id: invitationId, token: invitationToken, projectId } = request;

    const response = {
      invitationId,
      invitationToken,
      invitationAction: action.toUpperCase(),
      projectId,
    };

    try {
      await respondToInvitation(response);
      dispatch({ type: 'request/respond', payload: { invitationId } });
      if (response.invitationAction === 'ACCEPT') {
        const projectsCount = await fetchCountsOfProjects();
        reduxDispatch(setProjectCounterState(projectsCount));
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <RequestsContext.Provider
      value={{
        ...state,
        respondToRequest,
      }}
    >
      {isLoading ? <Loading /> : children}
    </RequestsContext.Provider>
  );
}

export const useRequests = () => {
  const context = useContext(RequestsContext);
  if (context === undefined) throw new Error('Requests context was used outside of provider');
  return context;
};

export default RequestsProvider;
