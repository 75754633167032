import { Box, styled, Grid, Card, Container } from '@mui/material';
import { useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';

import LeadsProgress from './LeadsProgress';

import { formatCurrency, handleAxiosError } from 'app/utils/helpers';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useProject } from 'app/contexts/ProjectContext';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '130px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});
const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: '#4F90F0',
});

const validationSchema = Yup.object().shape({
  eachSharePrice: Yup.number()
    .required('Each share price is required')
    .min(1, 'Each share price must be greater than 0')
    .max(
      Yup.ref('totalInvestment'),
      'Each share price must be less than or equal to total investment',
    ),
  landCost: Yup.number()
    .required('Land cost is required')
    .min(1, 'Land cost must be greater than 0'),

  softCost: Yup.number()
    .required('Soft cost is required')
    .min(1, 'Soft cost must be greater than 0'),
  totalInvestment: Yup.number()
    .required('Total investment is required')
    .min(1, 'Total investment must be greater than 0'),
  totalShare: Yup.number()
    .required('Total share is required')
    .min(1, 'Total share must be greater than 0'),
});

const LeadsInvestmentinformation = () => {
  const { isOnHoldOrCancelled, investment, updateInvestment } = useProject();
  const [open, setOpen] = useState(false);
  const initialValues = { ...investment };

  const { totalInvestment, landCost, softCost, totalShare, eachSharePrice } = initialValues;

  const handleEditClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const infoPairs = [
    { key: 'TOTAL INVESTMENT', value: formatCurrency(totalInvestment) },
    { key: 'LAND COST', value: formatCurrency(landCost) },
    { key: 'SOFT COST', value: formatCurrency(softCost) },
  ];

  const handleFormikSubmit = async (values) => {
    try {
      await updateInvestment(values);
      handleClose();
    } catch (error) {
      handleAxiosError(error);
    }
  };

  return (
    <div className='viewlead'>
      <SubtitleDivider />
      <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title>Investment Details :</Title>
          <Button
            style={{ color: '#4F90F0', fontSize: '14px' }}
            onClick={handleEditClick}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} />
            Edit
          </Button>
        </Box>
        <InfoContainer item lg={6} md={6} sm={12} xs={12}>
          {infoPairs.map(({ key, value }, index) => (
            <Box key={index}>
              <Key>{key}</Key>
              <ValueContainer>
                <Value className='basicvalues'>{value ?? ''}</Value>
              </ValueContainer>
            </Box>
          ))}
        </InfoContainer>
        <>
          <Card>
            <Grid container mt={1} mb={1}>
              <Container>
                <LeadsProgress
                  total={totalShare}
                  sold={0}
                  available={totalShare}
                  cost={eachSharePrice}
                />
              </Container>
            </Grid>
          </Card>
        </>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleFormikSubmit}
        >
          {({
            values,
            isSubmitting,
            errors,
            touched,
            setValues,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => {
            const handleFieldChange = (e) => {
              const { name, value } = e.target;
              const numericValue = parseFloat(value) || 0;

              if (numericValue >= 0) {
                const updatedValues = { ...values, [name]: numericValue };

                if (name === 'landCost' || name === 'softCost') {
                  const updatedTotalInvestment = updatedValues.landCost + updatedValues.softCost;

                  updatedValues.totalInvestment = updatedTotalInvestment;

                  const eachSharePrice = parseFloat(updatedValues.eachSharePrice);
                  if (eachSharePrice > 0) {
                    updatedValues.totalShare = updatedTotalInvestment / eachSharePrice;
                  }
                }

                if (name === 'eachSharePrice') {
                  const totalInvestment = parseFloat(updatedValues.totalInvestment);
                  if (numericValue > 0) {
                    updatedValues.totalShare = totalInvestment / numericValue;
                  }
                }

                handleChange(e);
                setValues((prevValues) => ({ ...prevValues, ...updatedValues }));
              }
            };
            return (
              <form onSubmit={handleSubmit}>
                <DialogTitle>Update Investment Details</DialogTitle>
                <DialogContent>
                  <TextField
                    fullWidth
                    margin='normal'
                    variant='outlined'
                    label='Each share price'
                    inputProps={{ min: 0 }}
                    name='eachSharePrice'
                    type='number'
                    value={values.eachSharePrice}
                    onChange={handleFieldChange}
                    onBlur={handleBlur}
                    placeholder='e.g. $30000.00'
                    helperText={touched.eachSharePrice && errors.eachSharePrice}
                    error={Boolean(errors.eachSharePrice && touched.eachSharePrice)}
                    disabled={isSubmitting}
                  />
                  <TextField
                    fullWidth
                    margin='normal'
                    variant='outlined'
                    label='Land cost'
                    inputProps={{ min: 0 }}
                    name='landCost'
                    type='number'
                    value={values.landCost}
                    onChange={handleFieldChange}
                    onBlur={handleBlur}
                    placeholder='e.g. $30000.00'
                    helperText={touched.landCost && errors.landCost}
                    error={Boolean(errors.landCost && touched.landCost)}
                    disabled={isSubmitting}
                  />
                  <TextField
                    fullWidth
                    margin='normal'
                    variant='outlined'
                    label='Soft cost'
                    inputProps={{ min: 0 }}
                    name='softCost'
                    type='number'
                    value={values.softCost}
                    onChange={handleFieldChange}
                    onBlur={handleBlur}
                    placeholder='e.g. $30000.00'
                    helperText={touched.softCost && errors.softCost}
                    error={Boolean(errors.softCost && touched.softCost)}
                    disabled={isSubmitting}
                  />
                  <TextField
                    fullWidth
                    margin='normal'
                    variant='outlined'
                    label='Total Investment'
                    inputProps={{ min: 0 }}
                    name='totalInvestment'
                    type='number'
                    value={values.totalInvestment}
                    onChange={handleFieldChange}
                    onBlur={handleBlur}
                    placeholder='e.g. $30000.00'
                    helperText={touched.totalInvestment && errors.totalInvestment}
                    error={Boolean(errors.totalInvestment && touched.totalInvestment)}
                    disabled
                  />
                  <TextField
                    fullWidth
                    margin='normal'
                    variant='outlined'
                    label='Total Share'
                    inputProps={{ min: 0 }}
                    name='totalShare'
                    type='number'
                    value={values.totalInvestment / values.eachSharePrice}
                    onChange={handleFieldChange}
                    onBlur={handleBlur}
                    placeholder='e.g. $30000.00'
                    helperText={touched.totalShare && errors.totalShare}
                    error={Boolean(errors.totalShare && touched.totalShare)}
                    disabled
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <LoadingButton type='submit' color='primary' loading={isSubmitting}>
                    Update
                  </LoadingButton>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

export default LeadsInvestmentinformation;
