import React, { useState } from "react";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LeadSaleDetailModel from "./LeadSaleDetailModel";
import LeadDeleteConfirmation from "app/common/LeadsDeleteConfirmation";
import LeadSaleForm from "./LeadSaleForm"; // Ensure this path is correct

const LeadSaleCard = ({ saleDetails, buyerDetails, buildingName, saleId, handleDelete, buildingIndex, selectedunitIndex,saleIndex , selectedUnit}) => {
    const [openDetail, setOpenDetail] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDetail = () => setOpenDetail(true);
    const handleCloseDetail = () => setOpenDetail(false);
    const handleOpenEditForm = () => setOpenEditForm(true);
    const handleCloseEditForm = () => setOpenEditForm(false);

    return (
        <div className="sale-card" style={styles.saleCard}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container fontSize='18px' justifyContent="space-between" color='#565960' alignItems="center">
                        <Typography variant="h6">
                            Sale Space: <strong>{saleDetails?.saleSpace} Sq fts</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <IconButton onClick={handleOpenDetail}>
                                <VisibilityIcon />
                            </IconButton>
                            <IconButton onClick={handleOpenEditForm}>
                                <BorderColorOutlinedIcon />
                            </IconButton>
                            <IconButton onClick={() => setOpenDialog(true)}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Typography sx={{ display: 'flex', justifyContent: 'space-between', color: 'grey', fontSize: '14px' }} variant="body2">
                        {saleDetails?.saleSpace} price • {saleDetails?.saleTerm} sale term
                    </Typography>
                </Grid>

                <Grid item xs={6} style={styles.section}>
                    <Typography variant="subtitle2">CONTACT PERSON</Typography>
                    <Typography variant="body2"><strong>{buyerDetails?.contactPerson}</strong></Typography>
                    <Typography variant="body2">{buyerDetails?.phoneNo} • {buyerDetails?.email}</Typography>
                    <Typography variant="body2">{buyerDetails?.address}</Typography>
                </Grid>
            </Grid>

            <LeadSaleDetailModel
                open={openDetail}
                onClose={handleCloseDetail}
                initialVallues={saleDetails}
                buyerDetails={buyerDetails}
                onSave={(updatedSale) => {
                    handleCloseEditForm();
                }}
                selectedunitIndex={selectedunitIndex}
                buildingIndex={buildingIndex}
                saleIndex={saleIndex}
            />
            <LeadSaleForm
                open={openEditForm}
                onClose={handleCloseEditForm}
                onSave={(updatedSale) => {
                    handleCloseEditForm();
                }}
                initialValues={saleDetails}
                buyerDetails={buyerDetails}
                selectedunitIndex={selectedunitIndex}
                buildingIndex={buildingIndex}
                saleIndex={saleIndex}
                selectedUnit={selectedUnit}
            />
            <LeadDeleteConfirmation
                name={'Sale Space'}
                from={`from building ${buildingName}`}
                openModal={openDialog}
                handleCancel={() => setOpenDialog(false)}
                handleSubmit={() => handleDelete(saleId)}
            />
        </div>
    );
};

const styles = {
    saleCard: {
        border: '1px solid #e0e0e0',
        borderRadius: 8,
        padding: 16,
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: 16,
        marginTop: 16,
    },
    section: {
        borderTop: '1px solid #e0e0e0',
        paddingTop: 8,
        marginTop: 8,
    },
};

export default LeadSaleCard;
