import React, { useState } from "react";
import LeadLeaseDetailModel from "./LeadLeaseDetailModel"; // Modal for viewing lease details
import LeadLeaseForm from "./LeadLeaseForm"; // Form for editing lease details
import dayjs from "dayjs";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LeadDeleteConfirmation from "app/common/LeadsDeleteConfirmation"; // Confirmation dialog for deleting

const LeadLeaseCard = ({ leaseDetails, tenantDetails,onEdit, buildingName, leaseId, handleDelete,buildingIndex,selectedunitIndex,leaseIndex, selectedUnit }) => {
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleEditOpen = () => setEditOpen(true);
    const handleClose = () => {
        setOpen(false);
        setEditOpen(false);
    };

    return (
        <div className="lease-card" style={styles.leaseCard}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container fontSize='18px' justifyContent="space-between" color='#565960' alignItems="center">
                        <Typography variant="h6">
                            Lease Space: <strong>{leaseDetails.leaseSpace} Sq fts</strong>
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <IconButton onClick={handleOpen}>
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton onClick={handleEditOpen}>
                                    <BorderColorOutlinedIcon />
                                </IconButton>
                                <IconButton onClick={() => setOpenDialog(true)}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                    <Typography sx={{ display: 'flex', justifyContent: 'space-between', color: 'grey', fontSize: '14px' }} variant="body2">
                        {leaseDetails.rentSqFtPerYear ? `${leaseDetails.rentSqFtPerYear} rent/year • ` : ''}{leaseDetails.leaseTerm} lease term • {leaseDetails.securityDeposit} Security deposit
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: '500' }} >
                        Lease Period: {dayjs(leaseDetails.leaseStartDate).format('DD MMM YYYY')} - {dayjs(leaseDetails.leaseEndDate).format('DD MMM YYYY')}
                    </Typography>
                </Grid>

                <Grid item xs={6} style={styles.section}>
                    <Typography variant="subtitle2">TENANT DETAILS</Typography>
                    <Typography variant="body2"><strong>{tenantDetails?.contactPerson || 'N/A'}</strong></Typography>
                    <Typography variant="body2">{tenantDetails?.phoneNo} • {tenantDetails?.email}</Typography>
                    <Typography variant="body2">{tenantDetails?.address || 'No Address'}</Typography>
                </Grid>

                <Grid item xs={6} style={styles.section}>
                    <Typography variant="subtitle2">LEASE DETAILS</Typography>
                    <Typography variant="body2"><strong>Lease Term: </strong>{leaseDetails.leaseTerm || 'N/A'}</Typography>
                    <Typography variant="body2"><strong>Rent Increase Per Year: </strong>{leaseDetails.rentIncreasePerYear || 'N/A'}</Typography>
                    <Typography variant="body2"><strong>Security Deposit: </strong>{leaseDetails.securityDeposit || 'N/A'}</Typography>
                </Grid>
            </Grid>

            <LeadLeaseDetailModel
                open={open}
                open1={editOpen}
                onClose={handleClose}
                leaseDetails={leaseDetails}
                tenantDetails={tenantDetails}
                buildingIndex={buildingIndex}
                selectedunitIndex={selectedunitIndex}
                leaseIndex={leaseIndex}
                handleSave={onEdit}
            />

            <LeadLeaseForm
                open={editOpen}
                onClose={handleClose}
                tenantDetails={tenantDetails}
                leaseDetails1={leaseDetails}
                handleSave={onEdit}
                buildingIndex={buildingIndex}
                selectedunitIndex={selectedunitIndex}
                leaseIndex={leaseIndex}
                selectedUnit={selectedUnit}
            />

            <LeadDeleteConfirmation
                name={'Lease Space'}
                from={`from building ${buildingName}`}
                openModal={openDialog}
                handleCancel={() => setOpenDialog(false)}
                handleSubmit={() => handleDelete(leaseId)}
            />
        </div>
    );
};

// Styling for the lease card component
const styles = {
    leaseCard: {
        border: '1px solid #e0e0e0',
        borderRadius: 8,
        padding: 16,
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: 16,
        marginTop: 16,
    },
    section: {
        borderTop: '1px solid #e0e0e0',
        paddingTop: 8,
        marginTop: 8,
    },
};

export default LeadLeaseCard;
