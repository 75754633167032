import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  Tooltip,
  IconButton,
  Link,
  Modal,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import LeadsExpensesModal from './LeadsExpensesModal';
import { getExpensesByInvoice } from 'app/contexts/leadActions';
import { ConstructionOutlined } from '@mui/icons-material';
import notify from 'app/utils/notify';

const LeadsExpenseInvoice = ({ expensesDetails, handleDownload, setHandleViewData, id, initialDocuments }) => {
  const [open, setOpen] = useState(false);
  const [expense, setExpense] = useState(expensesDetails.find((expense1) => expense1?.id === id));
  const [disable, setDisable] = useState(expense?.paymentDetails?.length > 0 ? expense?.paymentDetails[expense?.paymentDetails.length - 1]?.status : null);
  const [initialExpense, setInitialExpense] = useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    const fetchExpense = async () => {
      try {
        if (expense) {
          setLoading(true);
          const response = await getExpensesByInvoice(expense?.projectId, expense?.invoiceNo, expense?.id);
          setInitialExpense(response);
        }
      } catch (error) {
        notify.error('Error fetching expense data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchExpense();
  }, [expense, expense?.id, expense?.invoiceNo, expense?.projectId]);


  React.useEffect(() => {
    const updatedExpense = expensesDetails.find((expense1) => expense1?.id === id);
    setExpense(updatedExpense);
    if (updatedExpense?.paymentDetails) {
      setDisable(
        updatedExpense.paymentDetails[updatedExpense.paymentDetails.length - 1]?.status
      );
    }
  }, [expensesDetails, id]);

  if (!expense) {
    return <Typography>No expense data available</Typography>;
  }


  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });


    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return `${formattedDate}, ${formattedTime}`;
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: '100%', marginTop: '1%' }}>
      <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
        <Typography>
          {expense?.expensesName} (Invoice: {expense?.invoiceNo})
        </Typography>
        <Link component='button' disabled={disable === "Paid"} sx={{
          marginRight: '1%',
          //  pointerEvents: disable === "Paid" ? 'none' : 'auto',
          color: disable === "Paid" ? '#9e9e9e' : '#1e88e5',
          textDecoration: disable === "Paid" ? 'none' : 'underline',
          cursor: disable === "Paid" ? 'not-allowed' : 'pointer',
        }} onClick={handleClickOpen}>
          Update Balance
        </Link>
      </Box>

      {loading ? (
        <Typography variant='h6'>Loading Expense Details ...</Typography>
      ) : (Array.isArray(initialExpense) && initialExpense.length > 0 ? (
        initialExpense.map((expensePD) => (
          <Paper
            elevation={3}
            sx={{
              p: 2,
              width: '100%',
              marginBottom: '1%',
            }}
            key={expensePD.id}>
            <Typography color='#5E6176' align='center' bgcolor='#EBEAEA' fontWeight='500' width='155px' gutterBottom>
              {formatDateTime(expensePD.updatedDate)}
            </Typography>
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={3}>
                <Typography variant='body2' color='textSecondary'>
                  TOTAL AMOUNT
                </Typography>
                <Typography variant='h6'>${expensePD.totalAmount.toFixed(2)}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant='body2' color='textSecondary'>
                  PAID AMOUNT
                </Typography>
                <Typography variant='h6' color='primary'>
                  ${expensePD?.paidAmount.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant='body2' color='textSecondary'>
                  BALANCE AMOUNT
                </Typography>
                <Typography variant='h6' color='green'>
                  {/* ${(expensePD.totalAmount - expensePD.paidAmount).toFixed(2)} */}
                  ${(expensePD?.balance).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant='body2' color='textSecondary'>
                  PAYMENT MODE
                </Typography>
                <Typography variant='h6'>{expensePD?.modeOfPayment}</Typography>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Typography variant='body2' color='textSecondary'>
                  PAID TO
                </Typography>
                <Typography variant='h6'>{expensePD.paidTo}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant='body2' color='textSecondary'>
                  Expense Type
                </Typography>
                <Typography variant='h6'>{expensePD?.expenseType}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant='body2' color='textSecondary'>
                  STATUS
                </Typography>
                <Typography variant='h6'>{expensePD?.status}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant='body2' color='textSecondary'>
                  COMMENTS
                </Typography>
                <Typography variant="body2">
                  {expensePD.comments.split(' ').length > 10 ? (
                    <>
                      {expensePD.comments.split(' ').slice(0, 10).join(' ')}&nbsp;
                      <Tooltip title={expensePD?.comments|| ''} placement="top">
                        <span style={{ cursor: 'pointer', color: 'blue', fontSize: '1.5em' }}>...</span>
                      </Tooltip>
                    </>
                  ) : (
                    expensePD?.comments
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ))
      ) : (
        <Typography>No payment details available</Typography>
      ))}

      {/* <Box display={'flex'} alignItems={'center'}>
        <LoadingButton
          variant='contained'
          style={{
            boxShadow: 'none',
            cursor: 'pointer',
            padding: '1px',
            backgroundColor: 'transparent',
          }}
          onClick={handleClickOpen}
        >
          Update Expenses
        </LoadingButton>
      </Box> */}
      <Modal open={open} onClose={handleClose}>
        <LeadsExpensesModal handleCloseModal={handleClose} expense={expense} />
      </Modal>
    </Box>
  );
};

export default LeadsExpenseInvoice;

