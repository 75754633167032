import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material';
import { purple, brown } from '@mui/material/colors';

const actions = [
  { user: 'Michelle John', action: 'Added a new member', date: 'Feb 12 2024', color: '#AB84B0' },
  {
    user: 'Danny Carie',
    action: 'uploaded the project plan documents',
    date: 'Feb 12 2024',
    color: '#D5A872',
  },
  { user: 'Michelle John', action: 'Added a new member', date: 'Feb 12 2024', color: '#AB84B0' },
  {
    user: 'Danny Carie',
    action: 'uploaded the project plan documents',
    date: 'Feb 12 2024',
    color: '#D5A872',
  },
  { user: 'Michelle John', action: 'Added a new member', date: 'Feb 12 2024', color: '#AB84B0' },
  {
    user: 'Danny Carie',
    action: 'uploaded the project plan documents',
    date: 'Feb 12 2024',
    color: '#D5A872',
  },
];

const AuditLog = ({ leads }) => {
  return (
    <>
      <List>
        {actions.map((action, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: action.color }}>
                {action.user
                  .split(' ')
                  .map((name) => name[0])
                  .join('')}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='primary' component='span' sx={{ fontSize: '0.875rem' }}>
                  {action.user}
                </Typography>
              }
              secondary={
                <>
                  <Typography component='span' color='black' sx={{ fontSize: '0.75rem' }}>
                    {action.action}
                  </Typography>
                  <Typography
                    component='span'
                    variant='body2'
                    color='textSecondary'
                    sx={{ fontSize: '0.75rem' }}
                  >
                    {action.date}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};
export default AuditLog;
