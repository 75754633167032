import * as Yup from 'yup';

export const generateMembersSchema = (projectMembers = [], isEditing = false) => {
  return Yup.object().shape({
    members: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/^[a-zA-Z\s]+$/, 'Name can only contain letters and spaces!')
          .required('Name is required!'),
        phone: Yup.string()
          .matches(
            /^\+\d{10,12}$/,
            'Please specify country code with valid mobile number (10-12 digits)',
          )
          .required('Mobile number is required!')
          .test('unique-phone', 'This phone number is already in use', function (value) {
            if (!value || isEditing) return true;

            const { createError } = this;
            const members = this.from[1].value.members;
            const phoneCount = members.filter((member) => member.phone === value).length;

            if (phoneCount > 1) {
              return createError({ message: 'This phone number is already in use in the form' });
            }

            const existsInProjectMembers = projectMembers.some((member) => member.phone === value);

            if (existsInProjectMembers) {
              return createError({
                message: 'This phone number is already associated with a project member',
              });
            }

            return true;
          }),
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required!')
          .test('unique-email', 'This email is already in use', function (value) {
            if (!value || isEditing) return true;

            const { createError } = this;
            const members = this.from[1].value.members;
            const emailCount = members.filter((member) => member.email === value).length;

            if (emailCount > 1) {
              return createError({ message: 'This email is already in use in the form' });
            }

            const existsInProjectMembers = projectMembers.some((member) => member.email === value);

            if (existsInProjectMembers) {
              return createError({
                message: 'This email is already associated with a project member',
              });
            }

            return true;
          }),
        roles: Yup.array().min(1, 'At least one role should be selected!'),
      }),
    ),
  });
};

export const validationSchemas = {
  members: generateMembersSchema,
};
