import React, { useState } from 'react';
import { Modal, Typography, Grid, IconButton, Box, Button, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Title } from 'app/common/Typography';
import LeadLeaseForm from './LeadLeaseForm';
import { useProject } from 'app/contexts/ProjectContext';
import dayjs from 'dayjs';
import DescriptionIcon from '@mui/icons-material/Description';
import { DownloadIcon } from "app/common/icons";

const SectionTitle = styled(Typography)(({ theme }) => ({
  height: '37px',
  backgroundColor: '#E3ECFF',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  fontSize: '14px',
  fontWeight: '500',
}));

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const handleDownload = (base64Content, fileName, mimeType) => {
  try {
    const byteCharacters = atob(base64Content); // Decoding base64 content
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Trigger download and clean up
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Download failed:', error);
  }
};

const LeadLeaseDetailModel = ({
  open,
  onClose,
  leaseDetails,
  tenantDetails,
  open1,
  handleSave,
  leaseIndex,
  selectedunitIndex,
  buildingIndex,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { documents } = useProject();

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleFormClose = () => {
    setEditMode(false);
    onClose();
  };

  // Filter documents based on matching fileId with leaseDocuments
  const matchedDocuments = leaseDetails?.leaseDocuments?.map((docId) => {
    return documents.find((doc) => doc.metadata.fileId === docId);
  });

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '48%',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              p: 1,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>Details View</Title>
            <IconButton onClick={onClose}>
              <CloseIcon style={{ fontSize: 18, color: '#272937' }} />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box container spacing={2} p={1}>
            <Grid
              mb={2}
              p={1}
              item
              xs={12}
              sx={{
                border: '1px solid #DFDFE7',
                height: '93px',
                marginTop: '10px',
                backgroundColor: '#FCFEFF',
              }}
            >
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h6'>
                  Lease Space: <strong>{leaseDetails?.leaseSpace} Sq fts</strong>
                </Typography>
                <Box display='flex' alignItems='center'>
                  <Typography
                    sx={{ marginRight: 2, color: '#4F90F0', fontSize: '14px', cursor: 'pointer' }}
                    onClick={handleEditClick}
                  >
                    <BorderColorOutlinedIcon fontSize='14px' /> Edit
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' flexDirection='column'>
                <Typography fontSize='14px' fontWeight='500' variant='body2' color='#5C5D64'>
                  {leaseDetails?.rentPerSqFt} rent/year • {leaseDetails?.leaseTerm} lease term •{' '}
                  {leaseDetails?.securityDeposit} Security deposit
                </Typography>
                <Typography fontSize='14px' fontWeight='500' variant='body2'>
                  Lease Period: {dayjs(leaseDetails?.leaseStartDate).format('DD MMM YYYY')} -{' '}
                  {dayjs(leaseDetails.leaseEndDate).format('DD MMM YYYY')}
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              spacing={2}
              mb={2}
              p={1}
              sx={{ border: '1px solid #DFDFE7', backgroundColor: '#FCFEFF' }}
            >
              <Grid item xs={4}>
                <Typography color='#64748B' fontWeight='500' variant='subtitle2'>
                  Lease Fixturization Date
                </Typography>
                <Typography variant='body2'>{leaseDetails?.leaseFixturizationDate}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='#64748B' fontWeight='500' variant='subtitle2'>
                  Lease Execution Date
                </Typography>
                <Typography variant='body2'>{leaseDetails?.leaseExecutionDate}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='#64748B' fontWeight='500' variant='subtitle2'>
                  NNN
                </Typography>
                <Typography variant='body2'>{leaseDetails?.nnn}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography color='#64748B' fontWeight='500' variant='body2'>
                  Rent Increase Per Year:
                </Typography>
                <Typography variant='body2'>{leaseDetails?.rentIncreasePerYear}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='#64748B' fontWeight='500' variant='body2'>
                  Free Rent Months:
                </Typography>
                <Typography variant='body2'>{leaseDetails?.freeRentMonths}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='#64748B' fontWeight='500' variant='body2'>
                  First Month Rent Date:
                </Typography>
                <Typography variant='body2'>{leaseDetails?.firstMonthRentDate}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography color='#64748B' fontWeight='500' variant='body2'>
                  Realtor Commission:
                </Typography>
                <Typography variant='body2'>{leaseDetails?.realtorCommission}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color='#64748B' fontWeight='500' variant='body2'>
                  Realtor Rebate:
                </Typography>
                <Typography variant='body2'>{leaseDetails?.realtorRebate}</Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SectionTitle>Lease Documents:</SectionTitle>
              {matchedDocuments && matchedDocuments.length > 0 ? (
                // <Box display='flex' flexDirection='column' p={1}>
                //   {matchedDocuments.map(
                //     (doc, index) =>
                //       doc && (
                //         <Button key={index} startIcon={<PictureAsPdfIcon />} variant='outlined'>
                //           {doc.metadata.documentName}
                //         </Button>
                //       ),
                //   )}
                <Box display="flex" gap={2} p={1}>
                  {matchedDocuments.map((doc, index) => {
                    const isPdf = doc.metadata.documentType === "application/pdf";
                    const isWord = doc.metadata.documentType === "application/msword" ||
                      doc.metadata.documentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

                    return (
                      doc && (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          sx={{
                            border: '1px solid #E0E0E0',
                            borderRadius: '8px',
                            padding: '8px 16px',
                            minWidth: '150px',
                            backgroundColor: '#f9fafb'
                          }}
                        >
                          {/* Show PDF or Word Icon based on document type */}
                          {isPdf ? (
                            <PictureAsPdfIcon style={{ marginRight: 8, color: '#e53935' }} />
                          ) : isWord ? (
                            <DescriptionIcon style={{ marginRight: 8, color: '#1976d2' }} />
                          ) : (
                            <DescriptionIcon style={{ marginRight: 8, color: '#757575' }} />
                          )}

                          <Typography variant="body2" sx={{ flexGrow: 1 }}>
                            {doc.metadata.documentName}
                          </Typography>

                          <IconButton
                            onClick={() =>
                              handleDownload(
                                doc.content,
                                `${doc.metadata.documentName}`,
                                doc.metadata.documentType
                              )
                            }
                            sx={{ color: '#4caf50' }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Box>
                      )
                    );
                  })}
                </Box>
              ) : (
                <Typography>No documents available</Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <SectionTitle>Comments:</SectionTitle>
              <Typography p={1} color='#64748B' fontWeight='500' variant='body2'>
                {leaseDetails?.comments || 'Sample comments will be shown here'}
              </Typography>
            </Grid>

            <Grid
              container
              spacing={2}
              mt={2}
              p={2}
              sx={{ border: '1px solid #DFDFE7', backgroundColor: '#FCFEFF' }}
            >
              <Grid item xs={6}>
                <Typography variant='subtitle2' fontSize='14px' color='#6D718A' fontWeight='500'>
                  LLC DETAILS
                </Typography>
                <Typography fontSize='14px'>
                  <strong>{tenantDetails?.llcDetails?.llcName || 'N/A'}</strong>
                </Typography>
                <Typography fontSize='14px'>
                  {tenantDetails?.llcDetails?.llcNumber || 'N/A'} •{' '}
                  {tenantDetails?.llcDetails?.email}
                </Typography>
                <Typography fontSize='14px'>{tenantDetails?.llcDetails?.address}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant='subtitle2' fontSize='14px' color='#6D718A' fontWeight='500'>
                  CONTACT PERSON
                </Typography>
                <Typography fontSize='14px'>
                  <strong>{tenantDetails?.contactPerson || 'N/A'}</strong>
                </Typography>
                <Typography fontSize='14px'>
                  {tenantDetails?.phoneNo} • {tenantDetails?.email}
                </Typography>
                <Typography fontSize='14px'>{tenantDetails?.address}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      {editMode && (
        <LeadLeaseForm
          open={editMode}
          // openModal={() => setEditMode(true)}
          // handleCancel={() => setEditMode(false)}
          onClose={handleFormClose}
          leaseDetails1={leaseDetails}
          tenantDetails={tenantDetails}
          handleSave={handleSave}
          buildingIndex={buildingIndex}
          selectedunitIndex={selectedunitIndex}
          leaseIndex={leaseIndex}
        />
      )}
    </>
  );
};

export default LeadLeaseDetailModel;
