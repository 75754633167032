import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import styles from './modules/MemberView.module.css';

function DetailSection({ title, details }) {
  return (
    <div className={styles.contentBox}>
      <Typography variant='h6'>{title}:</Typography>
      <Grid container columnSpacing={1} rowSpacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        {details.map(({ key, value }) => (
          <Grid item xs={4} sm={4} md={6} key={key}>
            <Typography variant='subtitle1' color='text.secondary' className={styles.text}>
              {key.toUpperCase()}
            </Typography>
            {key === 'Roles' ? (
              <div>
                {value.map((role, index) => (
                  <Chip
                    key={index}
                    label={role}
                    sx={{ mt: 0.5, mr: index < value.length - 1 ? 0.5 : 0, fontWeight: 500 }}
                  />
                ))}
              </div>
            ) : (
              <Typography variant='subtitle1' className={styles.text}>
                {value}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function MemberView({ member, closeModal }) {
  const details = [
    {
      title: 'Personal Details',
      details: [
        { key: 'Name', value: member.memberName },
        { key: 'Roles', value: member.userRoles },
      ],
    },
    {
      title: 'Contact Details',
      details: [
        { key: 'Email', value: member.email },
        { key: 'Mobile Number', value: member.phone },
      ],
    },
  ];

  return (
    <Dialog open={true} onClose={closeModal}>
      <div className={styles.dialogContainer}>
        <div className={styles.headerContainer}>
          <DialogTitle>{member.memberName}</DialogTitle>
          <IconButton onClick={closeModal} sx={{ marginRight: 2 }}>
            <Close />
          </IconButton>
        </div>
        <div className={styles.subtitleContainer}>
          <img
            src='/assets/images/member-view.png'
            alt='Member view background'
            className={styles.backgroundImage}
          />
          <div className={styles.subtitleContent}>
            <Avatar style={{ width: 100, height: 100 }} src={''} />
            <Typography variant='h1'>PROFILE</Typography>
          </div>
        </div>
        <DialogContent className={styles.contentContainer}>
          {details.map(({ title, details }) => (
            <DetailSection key={title} title={title} details={details} />
          ))}
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default MemberView;
