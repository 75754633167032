import { API_ENDPOINTS } from './apiConstants';
import axiosService from './axiosService';
import notify from 'app/utils/notify';

export const fetchLeads = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.PROJECTS.LIST_PROJECTS);
    return response.data.data.records;
  } catch (error) {
    throw error;
  }
};

export const fetchRoles = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.ROLES.GET_ROLES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllUsers = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.USERS.GET_ALL_USERS);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const fetchNewLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_NEW_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const fetchOngoingLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_ONGOING_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCancelledLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_CANCELLED_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOnHoldLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_ONHOLD_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCompletedLeadsWithPagination = async (pageNo, pageSize) => {
  try {
    const response = await axiosService.Get(
      `${API_ENDPOINTS.PROJECTS.LIST_COMPLETED_PROJECTS}?pageNo=${pageNo}&pageSize=${pageSize}`,
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLeadsById = async (id) => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.PROJECTS.GET_PROJECT_DETAILS(id));
    return response.data.data.records[0];
  } catch (error) {
    throw error;
  }
};

export const fetchCountsOfProjects = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.PROJECTS.GET_PROJECTS_COUNT);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLeadsLocationById = async (id) => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.LOCATIONS.GET_PROJECT_LOCATION(id));
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLeadsDocById = async (id) => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.DOCUMENTS.GET_DOCUMENTS(id));
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProfileDocument = async (id) => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.DOCUMENTS.GET_PROFILE_DOCUMENTS(id));
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLeadsDocByUserId = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.DOCUMENTS.GET_ALL_DOCUMENTS);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLeadsMembersById = async (id) => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.MEMBERS.GET_PROJECT_MEMBERS(id));
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLeadsLLcById = async (id) => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.LLC.GET_LLC_DETAILS(id));
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserLLC = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.USERLLC.GET_USER_LLC_DETAILS);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserByEmail = async (email) => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.USERS.GET_USER_DETAILS_BY_EMAIL(email));
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const projectLeadInformation = async (obj) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.PROJECTS.CREATE_PROJECT, obj);
    return result;
  } catch (error) {
    throw error;
  }
};

export const projectDeleteLeadInformation = async (projectId) => {
  try {
    const result = await axiosService.Delete(API_ENDPOINTS.PROJECTS.DELETE_PROJECT(projectId));
    return result;
  } catch (error) {
    throw error;
  }
};

export const deleteUserLLCInformation = async (id) => {
  try {
    const result = await axiosService.Delete(API_ENDPOINTS.USERLLC.DELETE_USER_LLC(id));
    return result;
  } catch (error) {
    throw error;
  }
};

export const projectDocumentDelete = async (fileId) => {
  try {
    const result = await axiosService.Delete(
      API_ENDPOINTS.DOCUMENTS.DELETE_DOCUMENTS_BY_FILE_ID(fileId),
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const projectClosingContractDetails = async (projectId) => {
  try {
    const response = await axiosService.Get(
      API_ENDPOINTS.CLOSINGCONTRACT.GET_CLOSING_CONTRACT(projectId),
    );

    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const projectCreateClosingContract = async (projectId, obj) => {
  try {
    await axiosService.Post(API_ENDPOINTS.CLOSINGCONTRACT.CREATE_CLOSING_CONTRACT(projectId), obj);
  } catch (error) {
    throw error;
  }
};

export const projectUpdateLeadInformation = async (projectId, obj) => {
  try {
    await axiosService.Put(API_ENDPOINTS.PROJECTS.UPDATE_PROJECT(projectId), obj);
  } catch (error) {
    throw error;
  }
};

export const projectLeadLocationInformation = async (projectId, obj) => {
  try {
    await axiosService.Post(API_ENDPOINTS.LOCATIONS.CREATE_UPDATE_PROJECT_LOCATION(projectId), obj);
  } catch (error) {
    throw error;
  }
};

export const projectLeadDocumentInformation = async (projectId, obj) => {
  try {
    const response = await axiosService.Post(
      API_ENDPOINTS.DOCUMENTS.UPLOAD_DOCUMENTS(projectId),
      obj,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

export const projectLeadMemberInformation = async (projectId, obj) => {
  try {
    await axiosService.Post(API_ENDPOINTS.MEMBERS.ASSIGN_PROJECT_MEMBERS(projectId), obj);
  } catch (error) {
    throw error;
  }
};

export const projectLeadMemberUpdateRole = async (projectId, obj) => {
  try {
    await axiosService.Put(API_ENDPOINTS.MEMBERS.UPDATE_PROJECT_MEMBER_ROLE(projectId), obj);
  } catch (error) {
    throw error;
  }
};

export const projectLeadMemberDelete = async (projectId, obj) => {
  try {
    await axiosService.Delete(API_ENDPOINTS.MEMBERS.DELETE_PROJECT_MEMBER(projectId), obj);
  } catch (error) {
    throw error;
  }
};

export const projectLeadLLCInformation = async (projectId, obj) => {
  try {
    await axiosService.Post(API_ENDPOINTS.LLC.CREATE_UPDATE_LLC(projectId), obj);
  } catch (error) {
    throw error;
  }
};

export const saveUserLLCInfo = async (obj) => {
  try {
    await axiosService.Post(API_ENDPOINTS.USERLLC.CREATE_UPDATE_USER_LLC, obj);
  } catch (error) {
    throw error;
  }
};

export const projectUpdateType = async (projectId, obj) => {
  try {
    await axiosService.Put(API_ENDPOINTS.PROJECTS.UPDATE_PROJECT_TYPE(projectId), obj);
  } catch (error) {
    throw error;
  }
};

export const projectGetContractDetails = async (projectId) => {
  try {
    const response = await axiosService.Get(
      API_ENDPOINTS.CONTRACTDETAILS.GET_CONTRACT_DETAILS(projectId),
    );
    return response.data.data[0];
  } catch (error) {
    throw error;
  }
};

export const projectCreateUpdateContractDetails = async (projectId, obj) => {
  try {
    await axiosService.Post(
      API_ENDPOINTS.CONTRACTDETAILS.CREATE_UPDATE_CONTRACT_DETAILS(projectId),
      obj,
    );
  } catch (error) {
    throw error;
  }
};

export const projectCreateUpdateInvestmentDetails = async (projectId, obj) => {
  try {
    await axiosService.Post(
      API_ENDPOINTS.INVESTMENTDETAILS.CREATE_UPDATE_INVESTMENT_DETAILS(projectId),
      obj,
    );
  } catch (error) {
    throw error;
  }
};

export const projectGetInvestmentDetails = async (projectId) => {
  try {
    const response = await axiosService.Get(
      API_ENDPOINTS.INVESTMENTDETAILS.GET_INVESTMENT_DETAILS(projectId),
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const projectExpenseDetails = async (projectId) => {
  try {
    const response = await axiosService.Get(
      API_ENDPOINTS.EXPENSEDETAILS.GET_EXPENSE_DETAILS(projectId),
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getExpensesByInvoice=async(projectId, invoiceNo, expenseId)=>{
  try{
    const response=await axiosService.Get(
      API_ENDPOINTS.EXPENSEDETAILS.GET_EXPENSES_BY_INVOICE(projectId, invoiceNo, expenseId)
    )
    return response.data.data;
  }catch(error){
    throw error;
  }
}

export const projectExpenseCreateUpdate = async (projectId, obj) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.EXPENSEDETAILS.CREATE_UPDATE_EXPENSE_DETAILS(projectId),
      obj,
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const updateExpenses = async (projectId, invoiceNo, expenseId, data) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.EXPENSEDETAILS.UPDATE_EXPENSES(projectId, invoiceNo, expenseId),
      data,
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const projectLeaseDetails = async (projectId, unitId) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.LEASEDETAILS.GET_LEASE_DETAILS(projectId, unitId));
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const projectLeaseDelete = async (projectId, leaseId) => {
  try {
    const result = await axiosService.Delete(API_ENDPOINTS.LEASEDETAILS.DELETE_LEASE_DETAILS(projectId, leaseId));
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const projectSaleDelete = async (projectId, saleId) => {
  try {
    const result = await axiosService.Delete(API_ENDPOINTS.SALEDETAILS.DELETE_SALE_DETAILS(projectId, saleId));
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const projecSaleDetails = async (projectId, unitId) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.SALEDETAILS.GET_Sales_DETAILS(projectId, unitId));
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const projectBuildingCreateUpdate = async (projectId, obj) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.LEASEDETAILS.CREATE_UPDATE_BUILDING(projectId),
      obj,
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const projectBuildingUnitsCreateUpdate = async (projectId, buildingId, obj) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.LEASEDETAILS.CREATE_UPDATE_Units_BUILDING(projectId, buildingId),
      obj,
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const projectBuildingDetails = async (projectId) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.LEASEDETAILS.GET_LEASE_BUILDING(projectId));
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const projectLeaseCreateUpdate = async (projectId, obj) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.LEASEDETAILS.CREATE_UPDATE_LEASE(projectId),
      obj,
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const projectGCCreate = async (projectId, obj) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.GCDETAILS.CREATE_GC(projectId), obj);
    return result;
  } catch (error) {
    notify.error('Error in posting Data');
    throw error;
  }
};

export const projectGCDetails = async (projectId) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.GCDETAILS.GET_ALL_GC(projectId));
    return result.data.data;
  } catch (error) {
    throw error;
  }
};

export const projectGetGC = async (projectId, gcId) => {
  try {
    const result = await axiosService.Get(
      API_ENDPOINTS.GCDETAILS.GET_GC(projectId, gcId));
    return result;
  } catch (error) {
    throw error;
  }
};

export const updateGCDetails = async (projectId, gcId, data) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.GCDETAILS.UPDATE_GC(projectId, gcId), data
    )
    return result;
  } catch (error) {
    notify.error("Error in posting Data");
    throw error;
  }
};

export const addBuildingLease = async (projectId, data) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.LEASEDETAILS.CREATE_UPDATE_LEASE(projectId), data
    )
    return result;
  } catch (error) {
    notify.error("Error in posting Data");
    throw error;
  }
};

export const addBuildingSale = async (projectId, data) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.SALEDETAILS.CREATE_UPDATE_SALES(projectId), data
    )
    return result;
  } catch (error) {
    notify.error("Error in posting Data");
    throw error;
  }
};

export const getAllSalesDetails = async (projectId) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.SALEDETAILS.GET_ALL_SALES(projectId))
    return result.data.data;
  } catch (error) {
    throw error;
  }
}

export const getAllLeaseDetais = async (projectId) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.LEASEDETAILS.GET_ALL_LEASE(projectId))
    return result.data.data;
  } catch (error) {
    throw error;
  }
}


export const gcAddChangeOrders = async (projectId, gcId, data) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.GCDETAILS.ADD_CHANGE_ORDER(projectId, gcId), data
    )
    return result;
  } catch (error) {
    throw error;
  }
};


export const gcAddPayments = async (projectId, gcId, data) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.GCDETAILS.ADD_PAYMENTS(projectId, gcId), data
    )
    return result;
  } catch (error) {
    throw error;
  }
}

export const gcAddManagerSiteManager = async (projectId, gcId, data) => {
  try {
    const result = await axiosService.Post(
      API_ENDPOINTS.GCDETAILS.ADD_MANAGER_SITE(projectId, gcId), data
    )
    return result;
  } catch (error) {
    throw error;
  }
}

export const gcUpdateChangeOrders = async (projectId, gcId, data) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.GCDETAILS.UPDATE_CHANGE_ORDER(projectId, gcId), data
    )
    return result;
  } catch (error) {
    throw error;
  }
}

export const gcUpdatePayments = async (projectId, gcId,paymentId,data) => {
  try {
    const result = await axiosService.Put(API_ENDPOINTS.GCDETAILS.UPDATE_PAYMENTS(projectId, gcId,paymentId), data
    )
    return result;
  } catch (error) {
    throw error;
  }
};

export const gcUpdateManagerDetails = async (projectId, gcId, data) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.GCDETAILS.UPDATE_MANAGER(projectId, gcId), data)
    return result;
  } catch (error) {
    throw error;
  }
};

export const getAllPM = async (projectId) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.PMDETAILS.GET_ALL_PM(projectId))
    return result.data.data;
  } catch (error) {
    notify.error('Error in fetching Property Management Data');
  }
};

export const updatePM = async (projectId, pmId, data) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.PMDETAILS.UPDATE_PM(projectId, pmId), data);
    return result;
  } catch (error) {
    notify.error('Error in updating Property Management Data');
  }
};

export const getSinglePM = async (projectId, pmId) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.PMDETAILS.GET_SINGLE_PM(projectId, pmId));
    return result;
  } catch (error) {
    notify.error('Error in Fetching property Management Data');
  }
};

export const createPM = async (projectId, data) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.PMDETAILS.CREATE_PM(projectId), data);
    return result;
  } catch (error) {
    notify.error('Error in posting property Management Data');
  }
};

export const addPMCP = async (projectId, pmId, data) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.PMDETAILS.ADD_PMCP(projectId, pmId), data);
    return result;
  } catch (error) {
    notify.error('Error in adding Contact Persons')
  }
}
export const updatePMCP = async (projectId, pmId, data) => {
  try {
    const result = await axiosService.Put(API_ENDPOINTS.PMDETAILS.UPDATE_PMCP(projectId, pmId), data);
    return result;
  } catch (error) {
    notify.error('Error in updating Contact Persons');
  }
};

export const projectGetInvitations = async (projectId) => {
  try {
    const response = await axiosService.Get(
      API_ENDPOINTS.INVITATIONS.GET_PROJECT_INVITATIONS(projectId),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createCL = async (projectId, data) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.CONSTRUCTIONLENDERS.CREATE_CL(projectId), data);
    return result;
  } catch (error) {
    throw error;
  }
}

export const updateCL = async (projectId, lenderId, data) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.CONSTRUCTIONLENDERS.UPDATE_CL(projectId, lenderId), data);
    return result;
  } catch (error) {
    throw error;
  }
}
export const getAllCL = async (projectId) => {
  try {
    const result = await axiosService.Get(API_ENDPOINTS.CONSTRUCTIONLENDERS.GETALL_CL(projectId));
    return result?.data?.data;
  } catch (error) {
    if (error.data.data == null || undefined) {
      notify.error('no records found in Construction Lender details');
    } else {
      throw error;
    }
  }
}

export const addLoanPayments = async (projectId, CLId, data) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.CONSTRUCTIONLENDERS.ADD_LOANPAYMENTS(projectId, CLId), data);
    return result;
  } catch (error) {
    throw error;
  }
}

export const addLoanWithdrawal = async (projectId, lenderId, data) => {
  try {
    const result = await axiosService.Post(API_ENDPOINTS.CONSTRUCTIONLENDERS.ADD_LOANWITHDRAWAL(projectId, lenderId), data);
    return result;
  } catch (error) {
    throw error;
  }
}

export const updateLoanPayments = async (projectId, CLId, data) => {
  try {
    const result = await axiosService.Put(API_ENDPOINTS.CONSTRUCTIONLENDERS.UPDATE_LOANPAYMENTS(projectId, CLId), data);
    return result;
  } catch (error) {
    throw error;
  }
}

export const updateLoanWithdrawal = async (projectId, CLId, data) => {
  try {
    const result = await axiosService.Put(API_ENDPOINTS.CONSTRUCTIONLENDERS.UPDATE_LOANWITHDRAWAL(projectId, CLId), data);
    return result;
  } catch (error) {
    throw error;
  }
}
