import React, { useState } from 'react';
import { Box, Typography, styled, Card, Button, IconButton, Modal } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import GCManagerDetails from './GCManagerDetails';
import GCChangeOrderDetails from './GCChangeOrderDetails';
import GCSiteManagerDetails from './GCSiteManagerDetails';
import GCPaymentDetails from './GCPaymentDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GeneralContractorDetailsModal from './GeneralContractorDetailsModal';
import { Title, Paragraph } from 'app/common/Typography';

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '18%',
  marginTop: '-1px',
  rowGap: '20px',
  borderRadius: '5px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const GeneralContractorDetails = ({ contractors, id, setContractData }) => {
  const [open, setOpen] = useState(false);
  const [gccontractors, setGcContractors] = useState(contractors);
  const contractor = contractors[id];



  if (!contractor) {
    return <Typography>No contractor data available</Typography>;
  }

  const contract = [
    { key: 'LLC NAME', value: contractor?.contractorDetails?.llcName },
    { key: 'EMAIL ADDRESS', value: contractor?.contractorDetails?.emailId },
    { key: 'PHONE NUMBER', value: contractor?.contractorDetails?.phoneNum },
    { key: 'ADDRESS', value: contractor?.contractorDetails?.address },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className='viewlead'>
        <SubtitleDivider />
        <IconButton style={{ margin: '1%' }} onClick={() => setContractData(null)}>
          <ArrowBackIcon />
        </IconButton>

        <Box marginLeft={3} marginRight={3}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
              <Title>General Contractor</Title>
              <Button
                style={{ color: '#4F90F0', fontSize: '14px' }}
                onClick={handleClickOpen}
              >
                <BorderColorIcon sx={{ fontSize: '13.5px' }} />
                Edit
              </Button>
            </Box>
            <Modal open={open} onClose={handleClose}>
              <GeneralContractorDetailsModal contractors={gccontractors} contractor={contractor} handleCloseModal={handleClose} />
            </Modal>
            <Box marginTop={0} marginLeft={2}>

              <InfoContainer>
                {contract?.map(({ key, value }, index) => (
                  <Box key={index}>
                    <Key>{key}</Key>
                    <ValueContainer>
                      <Value>{value}</Value>
                    </ValueContainer>
                  </Box>
                ))}
              </InfoContainer>
            </Box>
          </Box>
        </Box>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <GCManagerDetails gcManager={contractors} gcManagerIndex={id} />
        </Card>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <GCSiteManagerDetails gcSiteManager={contractors} gcSiteManagerIndex={id} />
        </Card>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <GCChangeOrderDetails gcCChangeOrders={contractors} gcChangeOrderIndex={id} />
        </Card>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <GCPaymentDetails gcPayment={contractors} gcpaymentIndex={id} />
        </Card>
      </div>
    </>
  );
};

export default GeneralContractorDetails;
