export const API_BASE_URL = '/api/v1';

export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: `${API_BASE_URL}/authentication/login`,
    SEND_OTP: `${API_BASE_URL}/authentication/sendOtp`,
  },
  REGISTRATION: {
    REGISTER: `${API_BASE_URL}/registration/register`,
    CONFIRM_REGISTRATION: `${API_BASE_URL}/registration/confirmRegistration`,
    RESEND_CONFIRMATION_CODE: `${API_BASE_URL}/registration/resendConfirmationCode`,
  },
  USERS: {
    GET_USER_DETAILS: `${API_BASE_URL}/users/getUserDetails`,
    EDIT_USER_DETAILS: (userNameJWT) => `${API_BASE_URL}/users/updateUser/${userNameJWT}`,
    GET_USER_DETAILS_BY_EMAIL: (email) => `${API_BASE_URL}/users/getUsers/${email}`,
    GET_ALL_USERS: `${API_BASE_URL}/users/getAllUsers`,
  },
  PROJECTS: {
    LIST_PROJECTS: `${API_BASE_URL}/projects/lead/listProjects`,
    LIST_NEW_PROJECTS: `${API_BASE_URL}/projects/newproject/listProjects`,
    LIST_ONGOING_PROJECTS: `${API_BASE_URL}/projects/ongoing/listProjects`,
    LIST_CANCELLED_PROJECTS: `${API_BASE_URL}/projects/cancelled/listProjects`,
    LIST_ONHOLD_PROJECTS: `${API_BASE_URL}/projects/onhold/listProjects`,
    LIST_COMPLETED_PROJECTS: `${API_BASE_URL}/projects/completed/listProjects`,
    GET_PROJECT_DETAILS: (id) => `${API_BASE_URL}/projects/${id}/getProjectDetails`,
    UPDATE_PROJECT: (projectId) => `${API_BASE_URL}/projects/${projectId}/updateProject`,
    UPDATE_PROJECT_TYPE: (projectId) => `${API_BASE_URL}/projects/${projectId}/updateProjectType`,
    CREATE_PROJECT: `${API_BASE_URL}/projects/createProject`,
    DELETE_PROJECT: (projectId) => `${API_BASE_URL}/projects/${projectId}/deleteProject`,
    GET_PROJECTS_COUNT: `${API_BASE_URL}/projects/getProjectsCount`,
  },
  ROLES: {
    GET_ROLES: `${API_BASE_URL}/roles/getRoles`,
  },
  LOCATIONS: {
    GET_PROJECT_LOCATION: (id) => `${API_BASE_URL}/locations/${id}/getProjectLocation`,
    CREATE_UPDATE_PROJECT_LOCATION: (projectId) =>
      `${API_BASE_URL}/locations/${projectId}/createProjectLocation`,
  },
  DOCUMENTS: {
    GET_DOCUMENTS: (id) => `${API_BASE_URL}/documents/${id}/getDocuments`,
    GET_PROFILE_DOCUMENTS: () => `${API_BASE_URL}/documents/getProfileImage`,
    GET_ALL_DOCUMENTS: `${API_BASE_URL}/documents/getDocuments`,
    UPLOAD_DOCUMENTS: (projectId) => `${API_BASE_URL}/documents/${projectId}/uploadDocuments`,
    UPLOAD_PROFILE_DOCUMENT: () => `${API_BASE_URL}/documents/uploadProfileImage`,
    DELETE_DOCUMENTS_BY_FILE_ID: (fileId) => `${API_BASE_URL}/documents/deleteDocument/${fileId}`,
  },
  MEMBERS: {
    GET_PROJECT_MEMBERS: (projectId) => `${API_BASE_URL}/members/${projectId}/getProjectMembers`,
    ASSIGN_PROJECT_MEMBERS: (projectId) =>
      `${API_BASE_URL}/members/${projectId}/assignProjectMembers`,
    UPDATE_PROJECT_MEMBER_ROLE: (projectId) =>
      `${API_BASE_URL}/members/${projectId}/updateMemberRole`,
    DELETE_PROJECT_MEMBER: (projectId) =>
      `${API_BASE_URL}/members/${projectId}/deleteProjectMembers`,
  },
  LLC: {
    GET_LLC_DETAILS: (id) => `${API_BASE_URL}/llc/${id}/getLlcDetails`,
    CREATE_UPDATE_LLC: (projectId) => `${API_BASE_URL}/llc/${projectId}/createLlc`,
  },
  USERLLC: {
    GET_USER_LLC_DETAILS: `${API_BASE_URL}/llc/getUserLlc`,
    CREATE_UPDATE_USER_LLC: `${API_BASE_URL}/llc/createUserLlc`,
    DELETE_USER_LLC: (id) => `${API_BASE_URL}/llc/deleteUserLlc?id=${id}`,
  },
  COMMENTS: {
    GET_ALL_COMMENTS: (projectId) => `${API_BASE_URL}/comments/${projectId}/getAllComments`,
    CREATE_COMMENT: (projectId) => `${API_BASE_URL}/comments/${projectId}/addComment`,
    CREATE_COMMENT_REPLY: (projectId, parentId) =>
      `${API_BASE_URL}/comments/${projectId}/${parentId}/addReply`,
    UPDATE_COMMENT: (commentId, projectId) =>
      `${API_BASE_URL}/comments/${projectId}/updateComment/${commentId}`,
    DELETE_COMMENT: (commentId, projectId) =>
      `${API_BASE_URL}/comments/${projectId}/deleteComment/${commentId}`,
  },
  CONTRACTDETAILS: {
    GET_CONTRACT_DETAILS: (projectId) =>
      `${API_BASE_URL}/contractDetails/${projectId}/getContractDetails`,
    CREATE_UPDATE_CONTRACT_DETAILS: (projectId) =>
      `${API_BASE_URL}/contractDetails/${projectId}/createContractDetails`,
  },
  CLOSINGCONTRACT: {
    GET_CLOSING_CONTRACT: (projectId) =>
      `${API_BASE_URL}/closingContract/${projectId}/getClosingContract`,
    CREATE_CLOSING_CONTRACT: (projectId) =>
      `${API_BASE_URL}/closingContract/${projectId}/createClosingContract`,
  },
  INVESTMENTDETAILS: {
    GET_INVESTMENT_DETAILS: (projectId) =>
      `${API_BASE_URL}/investments/${projectId}/getInvestments`,
    CREATE_UPDATE_INVESTMENT_DETAILS: (projectId) =>
      `${API_BASE_URL}/investments/${projectId}/createInvestments`,
  },
  EXPENSEDETAILS: {
    GET_EXPENSE_DETAILS: (projectId) => `${API_BASE_URL}/expenses/${projectId}/getExpenses`,
    GET_EXPENSES_BY_INVOICE:(projectId, invoiceNo, expenseId)=>`${API_BASE_URL}/expenses/${projectId}/getByInvoiceNo?invoiceNo=${invoiceNo}&id=${expenseId}`,
    CREATE_UPDATE_EXPENSE_DETAILS: (projectId) =>
      `${API_BASE_URL}/expenses/${projectId}/createExpenses`,
    UPDATE_EXPENSES: (projectId, invoiceNo, expenseId) =>
      `${API_BASE_URL}/expenses/${projectId}/updateBalance/${invoiceNo}?id=${expenseId}`,
  },
  LEASEDETAILS: {
    GET_LEASE_DETAILS: (projectId, unitId) =>
      `${API_BASE_URL}/lease/${projectId}/getLease?unitId=${unitId}`,
    GET_LEASE_BUILDING: (projectId) => `${API_BASE_URL}/lease/${projectId}/getBuilding`,
    CREATE_UPDATE_LEASE: (projectId) => `${API_BASE_URL}/lease/${projectId}/createLease`,
    CREATE_UPDATE_BUILDING: (projectId) => `${API_BASE_URL}/lease/${projectId}/addBuilding`,
    CREATE_UPDATE_Units_BUILDING: (projectId, buildingId) =>
      `${API_BASE_URL}/lease/${projectId}/addUnits?buildingId=${buildingId}`,
    DELETE_LEASE_DETAILS: (projectId, leaseId) =>
      `${API_BASE_URL}/lease/${projectId}/deleteLease?id=${leaseId}`,
    GET_ALL_LEASE: (projectId) => `${API_BASE_URL}/lease/${projectId}/getAllLease`,
  },
  SALEDETAILS: {
    GET_Sales_DETAILS: (projectId, unitId) =>
      `${API_BASE_URL}/sales/${projectId}/getSales?unitId=${unitId}`,
    CREATE_UPDATE_SALES: (projectId) => `${API_BASE_URL}/sales/${projectId}/createSales`,
    DELETE_SALE_DETAILS: (projectId, saleId) =>
      `${API_BASE_URL}/sales/${projectId}/deleteSales?id=${saleId}`,
    GET_ALL_SALES: (projectId) => `${API_BASE_URL}/sales/${projectId}/getAllSales`,
  },
  GCDETAILS: {
    GET_ALL_GC: (projectId) => `${API_BASE_URL}/gc/${projectId}/getAllGeneralContractor`,
    GET_GC: (projectId, gcId) => `${API_BASE_URL}/gc/${projectId}/getGeneralContractor?id=${gcId}`,
    CREATE_GC: (projectId) => `${API_BASE_URL}/gc/${projectId}/createGeneralContractor`,
    UPDATE_GC: (projectId, gcId) =>
      `${API_BASE_URL}/gc/${projectId}/createGeneralContractor?id=${gcId}`,
    ADD_CHANGE_ORDER: (projectId, gcId) =>
      `${API_BASE_URL}/gc/${projectId}/addChangeOrder?generalContractorId=${gcId}`,
    ADD_PAYMENTS: (projectId, gcId) =>
      `${API_BASE_URL}/gc/${projectId}/addPayment?generalContractorId=${gcId}`,
    ADD_MANAGER_SITE: (projectId, gcId) =>
      `${API_BASE_URL}/gc/${projectId}/addManagerDetails?generalContractorId=${gcId}`,
    UPDATE_CHANGE_ORDER: (projectId, gcId) =>
      `${API_BASE_URL}/gc/${projectId}/updateChangeOrder?generalContractorId=${gcId}`,
    UPDATE_PAYMENTS: (projectId, gcId,paymentId) =>
      `${API_BASE_URL}/gc/${projectId}/updatePayment?id=${gcId}&paymentId=${paymentId}`,
    UPDATE_MANAGER: (projectId, gcId) =>
      `${API_BASE_URL}/gc/${projectId}/updateManagerDetails?generalContractorId=${gcId}`,
  },
  PMDETAILS: {
    GET_ALL_PM: (projectId) =>
      `${API_BASE_URL}/property-management/${projectId}/getAllPropertyManagement`,
    GET_SINGLE_PM: (projectId, pmId) =>
      `${API_BASE_URL}/property-management/${projectId}/getPropertyManagement?id=${pmId}`,
    CREATE_PM: (projectId) =>
      `${API_BASE_URL}/property-management/${projectId}/createPropertyManagement`,
    UPDATE_PM: (projectId, pmId) =>
      `${API_BASE_URL}/property-management/${projectId}/createPropertyManagement?id=${pmId}`,
    ADD_PMCP: (projectId, pmId) =>
      `${API_BASE_URL}/property-management/${projectId}/addContactPerson?propertyId=${pmId}`,
    UPDATE_PMCP: (projectId, pmId) =>
      `${API_BASE_URL}/property-management/${projectId}/updateContactPerson?propertyId=${pmId}`,
  },
  CONSTRUCTIONLENDERS: {
    CREATE_CL: (projectId) => `${API_BASE_URL}/lender-details/${projectId}/createLenderDetails`,
    UPDATE_CL: (projectId, lenderId) =>
      `${API_BASE_URL}/lender-details/${projectId}/createLenderDetails?id=${lenderId}`,
    GETALL_CL: (projectId) => `${API_BASE_URL}/lender-details/${projectId}/getLenderDetails`,
    ADD_LOANPAYMENTS: (projectId, CLId) =>
      `${API_BASE_URL}/lender-details/${projectId}/addLoanPayment?lenderId=${CLId}`,
    ADD_LOANWITHDRAWAL: (projectId, lenderId) =>
      `${API_BASE_URL}/lender-details/${projectId}/addLoanWithdrawal?lenderId=${lenderId}`,
    UPDATE_LOANPAYMENTS: (projectId, CLId) =>
      `${API_BASE_URL}/lender-details/${projectId}/updateloanPayments?lenderId=${CLId}`,
    UPDATE_LOANWITHDRAWAL: (projectId, CLId) =>
      `${API_BASE_URL}/lender-details/${projectId}/updateLoanWithdrawal?lenderId=${CLId}`,
  },
  INVITATIONS: {
    GET_ALL_INVITATIONS: `${API_BASE_URL}/invitations/getInvitations`,
    GET_INVITATION_BY_ID: (id) => `${API_BASE_URL}/invitations/getInvitations/${id}`,
    GET_INVITATION_BY_TOKEN: (token) =>
      `${API_BASE_URL}/invitations/getInvitationsByToken/${token}`,
    GET_PROJECT_INVITATIONS: (projectId) =>
      `${API_BASE_URL}/invitations/${projectId}/getInvitations`,
    RESPOND_TO_INVITATION: `${API_BASE_URL}/invitations/respond`,
  },
};
