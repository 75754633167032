import { Box, Card, styled, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import NavigationHeader from 'app/common/navigationHeader.jsx';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { topBarHeight } from 'app/utils/constant';
import ImagesProvider from 'app/contexts/ImagesContext';
import { useProject } from 'app/contexts/ProjectContext';
import LeadsBasicinformation from './LeadsData/LeadsBasicInformation';
import LeadLocationDetails from './LeadsData/LeadsLocationDetails';
import LeadsDocumentDetails from './LeadsData/LeadsDocumentDetails';
import LeadsContractDetails from './LeadsData/LeadContractInformation';
import LeadsClosingDetails from './LeadsData/LeadsClosingDetails';
import MembersSection from './LeadsData/Members/MembersSection.jsx';
import LeadsExpenseInformation from './LeadsData/LeadsExpenseInformation';
import LeadsLeasing from './LeadsData/LeadsLeasing';
import LeadImageGallery from './LeadsData/LeadImageGallery';
import LeadsLLCinformation from './LeadsData/LeadsLLCDetails';
import LeadsInvestmentinformation from './LeadsData/LeadInvestmentInformation';
import LeadsCancel from './LeadsData/LeadsCancel';
import LeadsOnHold from './LeadsData/LeadsOnHold';
import LeadsConfirmPage from './LeadsData/LeadsConfirmPage';
import LeadsOngoingConfirmation from './LeadsData/LeadOngoingConfirmation';
import LeadRestore from './LeadsData/LeadRestore';
import LeadLeaseSales from './LeadsData/Lease&Sale/LeadLeaseSales';
import LeadsGeneralContractorsInformation from './LeadsData/GC/LeadsGeneralContractorsInformation.jsx';
import PropertyManagementCompleteInfo from './LeadsData/PM/PropertyManagementCompleteInfo';
import LeadsContructionLenderInformation from './LeadsData/ContructionLender/LeadsContructionLenderInformation';

import RequestActions from '../Requests/RequestActions.jsx';
import useUserAuth from 'app/hooks/userUserAuth.js';

const Tab = styled('div')(({ active }) => ({
  cursor: 'pointer',
  borderBottom: active ? '4px solid #4F90F0' : 'none',
  padding: '10px',
  paddingRight: '15px',
  fontSize: '14px',
  fontWeight: active ? '600' : '500',
  color: active ? '#272937' : '#27293799',
  backgroundColor: active ? '#F0F0F0' : '',
}));

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.8rem 1.5rem',
  backgroundColor: '#ECF1F8',
  textTransform: 'capitalize',
}));

const CardTitle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ProjectDetails = () => {
  const {
    basic,
    requests,
    respondToRequest,
    projectType,
    previousProjectType,
    isCancelled,
    isOnHoldOrCancelled,
  } = useProject();
  const { user } = useUserAuth();
  const [selectedTab, setSelectedTab] = useState('Basic Details');
  const [modalState, setModalState] = useState({
    cancelled: false,
    ongoing: false,
    onhold: false,
    newproject: false,
    restore: false,
  });

  const currentUserRequest = requests.find(
    (request) => request.email === user.email && request.status === 'PENDING',
  );

  const types = {
    lead: 'Lead',
    newproject: 'New Project',
    ongoing: 'On-Going',
    onhold: 'On-Hold',
    cancelled: 'Cancelled',
    completed: 'Completed',
  };

  useEffect(() => {
    const cssSelector = document.querySelector('.css-kd3xim');
    if (cssSelector) {
      cssSelector.style.overflowY = 'hidden';
      return () => {
        cssSelector.style.overflowY = 'auto';
      };
    }
  }, []);

  const handleTabClick = (tabName) => setSelectedTab(tabName);

  const toggleModal = (modalType) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalType]: !prevState[modalType],
    }));
  };

  const getTabsForType = (projectType) => {
    const baseTabs = ['Basic Details', 'Location', 'Documents', 'Members'];

    const tabMapping = {
      lead: [...baseTabs],
      newproject: [
        'Basic Details',
        'Contract Details',
        'Location',
        'Documents',
        'Members',
        'Expenses',
        'General Contractor',
        'Lease & Sales',
        'Property Management',
        'Construction Lender',
      ],
      ongoing: [
        'Basic Details',
        'Contract Details',
        'Closing Details',
        'Location',
        'Documents',
        'Members',
        'Expenses',
        'General Contractor',
        'Lease & Sales',
        'Property Management',
        'Construction Lender',
      ],
    };

    return tabMapping[projectType] || [];
  };
  const tabs = getTabsForType(isOnHoldOrCancelled ? previousProjectType : projectType); // NOTE: previousProjectType will be the same as projectType for lead, newproject, ongoing. If onhold or cancelled - previousProjectType will reflect previous type.

  const renderTabs = () =>
    tabs.map((tab) => (
      <Tab key={tab} active={selectedTab === tab} onClick={() => handleTabClick(tab)}>
        {tab}
      </Tab>
    ));

  const renderSelectedContent = () => {
    switch (selectedTab) {
      case 'Basic Details':
        return <LeadsBasicinformation />;
      case 'Location':
        return <LeadLocationDetails />;
      case 'Documents':
        return <LeadsDocumentDetails />;
      case 'Contract Details':
        return <LeadsContractDetails />;
      case 'Closing Details':
        return <LeadsClosingDetails />;
      case 'Members':
        return <MembersSection />;
      case 'Expenses':
        return <LeadsExpenseInformation />;
      case 'Leasing':
        return <LeadsLeasing />;
      case 'Lease & Sales':
        return <LeadLeaseSales />;
      case 'General Contractor':
        return <LeadsGeneralContractorsInformation />;
      case 'Property Management':
        return <PropertyManagementCompleteInfo />;
      case 'Construction Lender':
        return <LeadsContructionLenderInformation />;
      default:
        return null;
    }
  };

  return (
    <div
      className='leadsContainer'
      style={{
        backgroundColor: '#F5F5F5',
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${topBarHeight}px)`,
      }}
    >
      <NavigationHeader
        project='Projects'
        lead={types[projectType]}
        customLeadName={`${basic.projectTitle} (${basic.id})`}
        showNewLeadButton={false}
        showLead={true}
        showChangeStatus={!isCancelled}
        handleAction={toggleModal}
        showtoggle={true}
        leadId={basic.id}
        projectType={projectType}
        previousProjectType={previousProjectType}
      />

      <div className='leadAllDetails'>
        <div>
          <Card elevation={3}>
            <CardHeader>
              <CardTitle>
                <Typography variant='h5' sx={{ fontSize: '1.5rem', fontWeight: '600' }}>
                  {basic.projectTitle}
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{ fontSize: '1rem', fontWeight: '500', color: '#64748B' }}
                >
                  {basic.description}
                </Typography>
              </CardTitle>
              {currentUserRequest && (
                <RequestActions request={currentUserRequest} respondToRequest={respondToRequest} />
              )}
            </CardHeader>
            {basic.reason && (
              <div
                style={{
                  borderTop: '1px solid #f0f0f0',
                  padding: '10px',
                  backgroundColor: '#F8EAED',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    border: '1px solid #F16588',
                    borderRadius: '20%',
                    color: '#F16588',
                    padding: '5px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  <WarningAmberRoundedIcon style={{ color: '#F16588' }} />
                </div>
                <span style={{ color: '#F16588' }}>
                  {`This project is ${types[projectType]} due to ${basic.reason}`}
                </span>
              </div>
            )}
            <ImagesProvider>
              <LeadImageGallery />
            </ImagesProvider>
          </Card>
          <div style={{ display: 'flex', padding: '10px', paddingBottom: '0px' }}>
            {renderTabs()}
          </div>
        </div>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        <Card elevation={3}>{renderSelectedContent()}</Card>
        {selectedTab === 'Basic Details' && (
          <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
            <LeadsLLCinformation />
          </Card>
        )}
        {selectedTab === 'Contract Details' && (
          <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
            <LeadsInvestmentinformation />
          </Card>
        )}
        {/* {selectedTab === 'Leasing' && (
          <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
            <LeadsLeaseDetails />
          </Card>
        )} */}
        {modalState.cancelled && (
          <LeadsCancel
            openModal={modalState.cancelled}
            handleCancel={() => toggleModal('cancelled')}
          />
        )}
        {modalState.onhold && (
          <LeadsOnHold openModal={modalState.onhold} handleCancel={() => toggleModal('onhold')} />
        )}
        {modalState.newproject && (
          <LeadsConfirmPage
            openModal={modalState.newproject}
            handleCancel={() => toggleModal('newproject')}
          />
        )}
        {modalState.ongoing && (
          <LeadsOngoingConfirmation
            openModal={modalState.ongoing}
            handleCancel={() => toggleModal('ongoing')}
          />
        )}
        {modalState.restore && (
          <LeadRestore openModal={modalState.restore} handleCancel={() => toggleModal('restore')} />
        )}
      </Box>
    </div>
  );
};

export default ProjectDetails;
