import notify from './notify';

export const parseAddressComponents = (place) => {
  let address1 = '';
  let address2 = '';
  let country = '';
  let city = '';
  let state = '';
  let zipCode = '';
  let latitude = null;
  let longitude = null;

  if (place.address_components) {
    const {
      address_components,
      geometry: { location },
      name,
    } = place;
    const { lat, lng } = location;
    latitude = lat();
    longitude = lng();

    address_components.forEach((component) => {
      const [type] = component.types;
      const longName = component.long_name;

      switch (type) {
        case 'landmark':
        case 'subpremise':
        case 'premise':
          address2 += address2 ? `, ${longName}` : longName;
          break;
        case 'route':
        case 'street_address':
        case 'street_number':
          address1 += address1 ? `, ${longName}` : longName;
          break;
        case 'country':
          country = longName;
          break;
        case 'locality':
        case 'postal_town':
        case 'administrative_area_level_3':
          city ||= longName;
          break;
        case 'administrative_area_level_1':
          state = longName;
          break;
        case 'postal_code':
          zipCode = longName;
          break;
        default:
          break;
      }
    });

    if (!address1 && name) {
      address1 = name;
    }
  }
  return {
    address1,
    address2,
    country,
    city,
    state,
    zipCode,
    latitude,
    longitude,
  };
};

export function handleAxiosError(error) {
  let errorMessage;

  switch (true) {
    case !!error.response: {
      const { status, data } = error.response;
      errorMessage = `Error ${status}: ${
        data?.message || data?.errors?.[0]?.errorMessage || 'Something went wrong on the server.'
      }`;
      break;
    }
    case !!error.request:
      errorMessage = 'No response from the server. Please check your internet connection.';
      break;
    default:
      errorMessage = `Request Error: ${error.message}`;
      break;
  }

  notify.error(errorMessage);
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatCurrency = (amount) => `$${(amount || 0).toFixed(2)}`;

export function convertUTCDateToLocalDate(date) {
  const inputDate = new Date(date);
  const newDate = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60 * 1000);
  return newDate;
}
