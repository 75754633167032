import React from 'react';
import { Box, styled, Card, Button } from '@mui/material';
import LeadCompanyDetails from './LeadCompanyDetails';
import LeadsLenderDetails from './LeadsLenderDetails';
import { formatCurrency } from 'app/utils/helpers';
import { useProject } from 'app/contexts/ProjectContext';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '130px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const LeadsClosingDetails = () => {
  const { isOnHoldOrCancelled, closing } = useProject();
  const infoPairs = [
    {
      key: 'PURCHASE PRICE',
      value: `${formatCurrency(closing.purchasePrice)}`,
    },
    {
      key: 'CLOSING COST',
      value: `${formatCurrency(closing.closingCost)}`,
    },
    { key: 'REALTOR REBATE', value: closing?.realtorRebate },
    {
      key: 'CLOSING DATE',
      value: closing?.closingDate ? new Date(closing?.closingDate).toLocaleDateString() : '',
    },
  ];

  return (
    <>
      <div className='viewlead'>
        <SubtitleDivider />
        <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Title>Closing Details :</Title>
            <Button
              // onClick={handleOpen}
              style={{ color: '#4F90F0', fontSize: '14px' }}
              disabled={isOnHoldOrCancelled}
            >
              <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
            </Button>
          </Box>
          <InfoContainer item lg={6} md={6} sm={12} xs={12}>
            {infoPairs.map(({ key, value }, index) => (
              <Box key={index}>
                <Key>{key}</Key>
                <ValueContainer>
                  <Value className='basicvalues'>{value}</Value>
                </ValueContainer>
              </Box>
            ))}
          </InfoContainer>
        </Box>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <LeadCompanyDetails />
        </Card>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <LeadsLenderDetails />
        </Card>
      </div>
    </>
  );
};

export default LeadsClosingDetails;
