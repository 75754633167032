import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import Document from 'app/views/dashboard/leads/NewLeads/Document';
import Images from 'app/views/dashboard/leads/NewLeads/Images';
import LeadInformation from 'app/views/dashboard/leads/NewLeads/LeadInformation';
import LlcDetailes from 'app/views/dashboard/leads/NewLeads/LlcDetailes';
import Location from 'app/views/dashboard/leads/NewLeads/Location';
import Members from 'app/views/dashboard/leads/NewLeads/Members';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fetchCountsOfProjects,
  projectDeleteLeadInformation,
  projectLeadDocumentInformation,
  projectLeadInformation,
  projectLeadLLCInformation,
  projectLeadLocationInformation,
  projectLeadMemberInformation,
} from 'app/contexts/leadActions';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { setProjectCounterState } from 'app/redux/reducers/ProjectCounterReducer';
import { handleAxiosError } from 'app/utils/helpers';
import { dueDiligence } from 'app/utils/constant';

function getSteps() {
  return ['Lead Information', 'Location', 'Documents', 'Images', 'LLC Details', 'Members'];
}

function getStepContent(
  stepIndex,
  formData,
  setFormData,
  leadLocationData,
  setLeadLocationData,
  document,
  setDocuments,
  LLCDetails,
  setLLCData,
  members,
  setMembers,
  setDisable,
) {
  switch (stepIndex) {
    case 0:
      return (
        <LeadInformation formData={formData} setFormData={setFormData} setDisable={setDisable} />
      );
    case 1:
      return (
        <Location
          formData={leadLocationData}
          setFormData={setLeadLocationData}
          setDisable={setDisable}
        />
      );
    case 2:
      return <Document formData={document} setFormData={setDocuments} setDisable={setDisable} />;
    case 3:
      return <Images formData={document} setFormData={setDocuments} setDisable={setDisable} />;
    case 4:
      return <LlcDetailes formData={LLCDetails} setFormData={setLLCData} setDisable={setDisable} />;
    case 5:
      return <Members formData={members} setFormData={setMembers} setDisable={setDisable} />;
    default:
      return 'Step not found';
  }
}

export default function StepperForm() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = React.useState({
    projectName: '',
    estimatedPrice: '',
    size: '',
    propertyType: '',
    subType: '',
  });
  const [leadLocationData, setLeadLocationData] = React.useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: null,
    longitude: null,
  });
  const [projectId, setProjectId] = useState('');
  const [document, setDocuments] = useState({
    documents: [{ docname: '', file: null }],
    images: [],
  });
  const [LLCDetails, setLLCData] = React.useState({
    llcName: '',
    einNum: '',
    address1: '',
    address2: '',
    country: '',
    city: '',
    state: '',
    zipCode: '',
    latitude: '',
    longitude: '',
  });
  const [members, setMembers] = useState({
    members: [
      {
        name: '',
        phone: '',
        email: '',
        roles: [],
      },
    ],
  });
  const steps = getSteps();
  const handleCancel = async () => {
    if (activeStep !== 0) {
      try {
        await projectDeleteLeadInformation(projectId);
      } catch (error) {
        handleAxiosError(error);
      }
    }
    navigate('/');
  };
  const dispatch = useDispatch();

  const handleNext = async () => {
    try {
      setLoading(true);
      let nextStep = activeStep;
      switch (activeStep) {
        case 0: {
          const data = {
            projectTitle: formData.projectName,
            estimatedPrice: formData.estimatedPrice,
            size: formData.size,
            description: formData.description,
            propertyType: formData.propertyType.label,
            subType: formData.subType.label,
            referenceLink: formData.referenceLink,
          };
          const result = await projectLeadInformation({ previousProjectType: 'lead', ...data });
          if (result && result.data) {
            setProjectId(result.data.data);
          }
          nextStep = 1;
          break;
        }

        case 1: {
          const data = {
            address1: leadLocationData.address1,
            address2: leadLocationData.address2,
            city: leadLocationData.city,
            state: leadLocationData.state,
            country: leadLocationData.country,
            zipCode: leadLocationData.zipCode,
            latitude: leadLocationData.latitude,
            longitude: leadLocationData.longitude,
          };

          await projectLeadLocationInformation(projectId, data);
          nextStep = 2;
          break;
        }

        case 2: {
          nextStep = 3;
          break;
        }

        case 3: {
          const data = new FormData();
          data.append('projectId', projectId);
          let docIndex = 0;
          let validDocumentCount = 0;

          document.documents.forEach((doc) => {
            if (doc.file) {
              validDocumentCount++;
            }
          });

          let imageIndex = validDocumentCount;

          document.documents.forEach((doc, index) => {
            if (doc.file) {
              data.append(`docReq[${docIndex}].file`, doc.file);
              data.append(`docReq[${docIndex}].docName`, doc.docname);
              data.append(`docReq[${docIndex}].type`, dueDiligence);
              docIndex++;
            }
          });

          document.images.forEach((doc, index) => {
            data.append(`docReq[${imageIndex}].file`, doc);
            data.append(`docReq[${imageIndex}].docName`, doc.name ? doc.name : '');
            data.append(`docReq[${imageIndex}].type`, dueDiligence);
            imageIndex++;
          });

          await projectLeadDocumentInformation(projectId, data);
          nextStep = 4;
          break;
        }

        case 4: {
          const data = {
            projectId: projectId,
            llcName: LLCDetails.llcName,
            einNum: LLCDetails.einNum,
            address1: LLCDetails.address1,
            address2: LLCDetails.address2,
            country: LLCDetails.country,
            state: LLCDetails.state,
            city: LLCDetails.city,
            zipCode: LLCDetails.zipCode,
            latitude: LLCDetails.latitude,
            longitude: LLCDetails.longitude,
          };
          await projectLeadLLCInformation(projectId, data);
          nextStep = 5;
          break;
        }
        case 5: {
          const memberRequests = members.members.map((member) => {
            const memberData = {
              name: member.name,
              phone: member.phone,
              email: member.email,
              roles: member.roles.map((role) => role.name),
            };
            return projectLeadMemberInformation(projectId, memberData);
          });
          await Promise.allSettled(memberRequests);
          const projectCounts = await fetchCountsOfProjects();
          dispatch(setProjectCounterState(projectCounts));
          navigate('/');
          nextStep = 0;
          break;
        }
        default:
          break;
      }
      setActiveStep((prevStep) => nextStep);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  const [disable, setDisable] = useState(true);
  const currentStepContent = getStepContent(
    activeStep,
    formData,
    setFormData,
    leadLocationData,
    setLeadLocationData,
    document,
    setDocuments,
    LLCDetails,
    setLLCData,
    members,
    setMembers,
    setDisable,
  );
  const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    marginLeft: '70px',
    padding: '8px',
    width: '80%',
    marginTop: '10px',
  });

  const uploadDocuments = async () => {
    const data = new FormData();
    data.append('projectId', projectId);
    let docIndex = 0;
  
    document.documents.forEach((doc) => {
      if (doc.file) {
        data.append(`docReq[${docIndex}].file`, doc.file);
        data.append(`docReq[${docIndex}].docName`, doc.docname);
        data.append(`docReq[${docIndex}].type`, dueDiligence);
        docIndex++;
      }
    });
  
    await projectLeadDocumentInformation(projectId, data);
  };

  const handleSkip = async () => {
    setActiveStep((prevStep) => {
      if (prevStep === 3) {
        (async () => {
          if (document.documents.some(doc => doc.file)) {
            try {
              await uploadDocuments();
            } catch (error) {
              handleAxiosError(error);
            }
          }
        })();
      }
      if (prevStep >= 5) {
        (async () => {
          const projectCounts = await fetchCountsOfProjects();
          dispatch(setProjectCounterState(projectCounts));
          navigate('/');
        })();
        return prevStep;
      }
      return prevStep + 1;
    });
  };

  return (
    <Box style={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className="stepper-container">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel className='stepperform'>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt={4}>
        <Box>
          <Box mt={4} padding={2}>
            <Grid item xs={6} margin={10} mt={0} mr={20}>
              <Typography>{currentStepContent}</Typography>
            </Grid>
            <div
              className='border-1'
              style={{
                border: '1px solid lightgrey',
                width: '100%',
                marginBottom: '20px',
              }}
            ></div>
            <Container className='stipperbtn'>
              <Grid container spacing={9}>
                <Grid item xs={3}>
                  <Button
                    className='canelbtn-1'
                    variant='outlined'
                    sx={{
                      color: 'grey !important',
                      borderColor: 'grey !important',
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={9}>
                  <Box display='flex' justifyContent='flex-end'>
                    {activeStep > 0 && (
                      <Button className='canelbtn' variant='outlined' onClick={handleSkip}>
                        Skip
                      </Button>
                    )}
                    <LoadingButton
                      type='submit'
                      className='canelbtn'
                      sx={{ ml: 2 }}
                      variant='contained'
                      color='primary'
                      loading={loading}
                      onClick={handleNext}
                      disabled={disable}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Save & Continue'}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
