import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, ButtonGroup, styled } from '@mui/material';
import LeadLeaseForm from './LeadLeaseForm';
import LeadAddBuilding from './LeadAddBuilding';
import LeadLeaseCard from './LeadLeaseCard';
import LeadSaleCard from './LeadSaleCard';
import LeadSaleForm from './LeadSaleForm';
import { useProject } from 'app/contexts/ProjectContext';
import { useParams } from 'react-router-dom';
import {
  projecSaleDetails,
  projectLeaseDelete,
  projectLeaseDetails,
  projectSaleDelete,
} from 'app/contexts/leadActions';
import SectionLoader from 'app/common/SectionLoader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});
const LeadBuildingDetails = ({
  selectedBuilding,
  setSelectedBuilding,
  handleBackToBuildings,
  buildingIndex,
}) => {
  const [showLeaseForm, setShowLeaseForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [unitLeaseDetails, setUnitLeaseDetails] = useState(null);
  const [saleDetails1, setSaleDetails1] = useState(null);
  const [viewMode, setViewMode] = useState('lease');
  const [showLeadSaleForm, setShowLeadSaleForm] = useState(false);
  const [loader, setLoader] = useState(false);
  const { leasesales } = useProject();
  const { projectId } = useParams();
  const [leaseToEdit, setLeaseToEdit] = useState(null);
  const [selectedunitIndex, setSelectedunitIndex] = useState(null);

  const handleSelectUnit = (unit, Index) => {
    setSelectedUnit(unit);
    setSelectedunitIndex(Index);
  };

  const handleOpenLeaseFormForEdit = (leaseDetails) => {
    setLeaseToEdit(leaseDetails); // Pre-fill form with lease details
    setShowLeaseForm(true);
  };

  const handleAddSaleClick = () => {
    setShowLeadSaleForm(true);
  };

  const handleCloseForm = () => {
    setShowLeadSaleForm(false);
  };

  const fetchLeaseAndSaleDetails = async () => {
    if (projectId && selectedUnit?.unitId) {
      setLoader(true);
      try {
        // Fetch Lease Details
        const unitLease = await projectLeaseDetails(projectId, selectedUnit?.unitId);
        setUnitLeaseDetails(unitLease);
      } catch (error) {
        console.error('Error fetching lease details:', error);
        setUnitLeaseDetails(null);
      }

      try {
        // Fetch Sale Details
        const unitsaleDetails = await projecSaleDetails(projectId, selectedUnit?.unitId);
        setSaleDetails1(unitsaleDetails);
      } catch (error) {
        console.error('Error fetching sale details:', error);
        setSaleDetails1(null);
      }

      setLoader(false);
    }
  };
  useEffect(() => {
    fetchLeaseAndSaleDetails();
  }, [selectedUnit, projectId, selectedBuilding]);

  useEffect(() => {
    if (selectedBuilding && selectedBuilding.units && selectedBuilding.units.length > 0) {
      setSelectedUnit(selectedBuilding.units[0]);
      setSelectedunitIndex(0);
    }
  }, []);

  useEffect(() => {
    const findAndSetSelectedBuilding = () => {
      if (!leasesales || leasesales.length === 0) {
        return;
      }
      const matchedBuilding = leasesales.find((building) => building.id === selectedBuilding.id);

      if (matchedBuilding) {
        setSelectedBuilding(matchedBuilding);
      }
    };
    findAndSetSelectedBuilding();
  }, [leasesales]);

  const handleOpenLeaseForm = () => {
    setShowLeaseForm(true);
  };

  const handleSaveBuilding = (newBuilding) => {
    if (newBuilding && newBuilding.name) {
      setShowModal(false);
    } else {
      console.error('Invalid building data:', newBuilding);
    }
  };

  const handleCloseLeaseForm = () => {
    setShowLeaseForm(false);
  };

  const filteredUnits = (selectedBuilding?.units || []).filter((unit) =>
    unit.unitName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleLeaseDelete = async (leaseId) => {
    await projectLeaseDelete(projectId, leaseId);
    fetchLeaseAndSaleDetails();
  };

  const handleSaleDelete = async (saleId) => {
    await projectSaleDelete(projectId, saleId);
    fetchLeaseAndSaleDetails();
  };

  return (
    <>
      <Box style={{ padding: '20px' }}>
        <Typography variant='h6' style={{ marginTop: '3px', marginBottom: '15px' }}>
          <span style={{ cursor: 'pointer' }} onClick={handleBackToBuildings}>
            <ArrowBackIcon sx={{ marginBottom: '-4px', width: '5%' }} />
          </span>
          {selectedBuilding?.buildingName || 'Building Name'}
        </Typography>

        <Box style={{ display: 'flex', flexDirection: 'row', gap: '20px', overflow: 'auto' }}>
          <Box
            style={{
              width: '280px',
              padding: '10px',
              border: '1px solid #E5E5E7',
              backgroundColor: '#F3F4F7',
            }}
          >
            <input
              type='text'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size='small'
              placeholder='Search with unit name...'
              style={{ width: '100%', padding: '5px', marginBottom: '10px' }}
            />
            <Button
              onClick={() => setShowModal(true)}
              variant='text'
              style={{ fontSize: '13px', fontWeight: '500' }}
            >
              + Add New Unit
            </Button>

            <Box
              style={{
                maxHeight: '300px',
                overflowY: 'auto',
                marginTop: '10px',
              }}
            >
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {filteredUnits.length > 0 ? (
                  filteredUnits.map((unit, index) => (
                    <li
                      key={unit.unitId}
                      onClick={() => handleSelectUnit(unit, index)}
                      style={{
                        backgroundColor:
                          selectedUnit?.unitId === unit.unitId ? '#DCE9FE' : 'transparent',
                        marginBottom: '8px',
                        padding: '10px',
                        borderLeft: selectedUnit?.unitId === unit.unitId && '5px solid #4F90F0',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      {unit.unitName || `Unit ${index + 1}`}
                    </li>
                  ))
                ) : (
                  <li>No units available</li>
                )}
              </ul>
            </Box>
          </Box>

          <Box
            style={{
              width: '75%',
              maxHeight: '300px',
              overflowY: 'auto',
              border: '1px solid #E3EDF5',
              borderRadius: '5px',
              padding: '20px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
              {/* <Typography variant='h6'>{selectedUnit?.unitName || 'Unit Name'}</Typography> */}
                <div>
                    <Typography variant="h6">
                        {selectedUnit?.unitName || 'Unit Name'}
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                        {selectedUnit?.space ? `${selectedUnit.space} Sq ft` : 'Space (Sq ft) not available'}
                    </Typography>
                </div>
              <ButtonGroup disableElevation variant='outlined' aria-label='Lease/Sale button group'>
                <Button
                  sx={{
                    width: '158px',
                    height: '35px',
                    color: viewMode === 'lease' ? '#fff' : 'black',
                    backgroundColor: viewMode === 'lease' ? '#4486C9' : 'transparent',
                    fontWeight: '500',
                  }}
                  onClick={() => setViewMode('lease')}
                >
                  Lease
                </Button>
                <Button
                  sx={{
                    width: '158px',
                    height: '35px',
                    color: viewMode === 'sale' ? '#fff' : 'black',
                    backgroundColor: viewMode === 'sale' ? '#4486C9' : 'transparent',
                    fontWeight: '500',
                  }}
                  onClick={() => setViewMode('sale')}
                >
                  Sale
                </Button>
              </ButtonGroup>
            </Box>
            <ModalDivider />

            {viewMode === 'lease' ? (
              <>
                <div
                  style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant='h6'>Lease Space</Typography>
                  <Button variant='text' onClick={handleOpenLeaseForm}>
                    + Add Lease
                  </Button>
                </div>

                <Box style={{ maxHeight: '300px', marginTop: '10px' }}>
                  {loader ? (
                    <SectionLoader />
                  ) : unitLeaseDetails && unitLeaseDetails.length > 0 ? (
                    unitLeaseDetails.map((details, index) => (
                      <LeadLeaseCard
                        key={index}
                        leaseDetails={details?.unitDetails?.leaseDetails}
                        onEdit={handleOpenLeaseFormForEdit}
                        tenantDetails={details?.unitDetails?.tenantDetails}
                        buildingName={selectedBuilding?.buildingName}
                        leaseId={details?.id}
                        handleDelete={handleLeaseDelete}
                        buildingIndex={buildingIndex}
                        selectedunitIndex={selectedunitIndex}
                        selectedUnit={selectedUnit}
                        selectedBuilding={selectedBuilding}
                        leaseIndex={index}
                      />
                    ))
                  ) : (
                    <Typography>No Lease Details Available</Typography>
                  )}
                </Box>

                <LeadLeaseForm
                  open={showLeaseForm}
                  onClose={handleCloseLeaseForm}
                  selectedBuilding={selectedBuilding}
                  selectedUnit={selectedUnit}
                  selectedunitIndex={selectedunitIndex}
                  buildingIndex={buildingIndex}
                />
              </>
            ) : (
              <>
                <div
                  style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant='h6'>Sale Space</Typography>
                  <Button variant='text' onClick={handleAddSaleClick}>
                    + Add Sale
                  </Button>
                </div>

                <Box style={{ maxHeight: '400px', marginTop: '5px' }}>
                  {loader ? (
                    <SectionLoader />
                  ) : saleDetails1 && saleDetails1.length > 0 ? (
                    saleDetails1.map((details, index) => {
                      if (!details.unitDetails) return null;
                      return (
                        <LeadSaleCard
                          key={details?.id}
                          buyerDetails={details?.unitDetails?.buyerDetails}
                          saleDetails={details?.unitDetails?.saleDetails}
                          buildingName={selectedBuilding?.buildingName}
                          saleId={details?.id}
                          handleDelete={handleSaleDelete}
                          buildingIndex={buildingIndex}
                          selectedunitIndex={selectedunitIndex}
                          selectedUnit={selectedUnit}
                          selectedBuilding={selectedBuilding}
                          saleIndex={index}
                        />
                      );
                    })
                  ) : (
                    <Typography>No Sale Details Available</Typography>
                  )}
                </Box>

                <LeadSaleForm
                  open={showLeadSaleForm}
                  onClose={handleCloseForm}
                  selectedUnit={selectedUnit}
                  selectedBuilding={selectedBuilding}
                  leaseDetails={leaseToEdit}
                  selectedunitIndex={selectedunitIndex}
                  buildingIndex={buildingIndex}
                />
              </>
            )}
          </Box>

          <LeadAddBuilding
            open={showModal}
            onClose={() => setShowModal(false)}
            onSave={handleSaveBuilding}
            initialBuildingName={selectedBuilding?.buildingName}
            buildingId={selectedBuilding?.id}
          />
        </Box>
      </Box>
    </>
  );
};

export default LeadBuildingDetails;
