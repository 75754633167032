import '../fake-db';
import { LoadScript } from '@react-google-maps/api';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Themes } from './components';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import { UserAuthProvider } from './contexts/UserAuthContext';

const libraries = ['places'];

const App = () => {
  return (
    <Provider store={Store}>
      <SettingsProvider>
        <Themes>
          <LoadScript
            googleMapsApiKey='AIzaSyCDUIkyDMVrgLh4Ax0Ki8DHcWAktv7qPTs'
            libraries={libraries}
            loadingElement={<div>Loading...</div>}
            defer={true}
          >
            <UserAuthProvider>
              <Outlet />
            </UserAuthProvider>
          </LoadScript>
        </Themes>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
